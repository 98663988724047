<app-navbar *ngIf="!inIframe()"></app-navbar>
<section class="hero-section" style="background-color: rgba(0, 0, 0, 0.05)">
	<div class="container">
		<div class="hs-images-container">
			<app-masked-illustration [illuContent]="illuContent"></app-masked-illustration>
		</div>
		<div class="hs-content">
			<custom-button class="button-return" color="secondary" level="primary" size="large"
				(click)="returnToLogin()" type="button" iconBefore="arrow-ios-back-outline">Retour</custom-button>

			<div class="hsc-titles">
				<h1 class="hsct-title">Vous avez oublié votre mot de passe ?</h1>
				<p class="hsct-subtitle">Saississez votre adresse courriel pour réintialiser votre mot de passe</p>
			</div>
			<form class="hsc-form" [formGroup]="forgotPasswordForm" (ngSubmit)="postForm()">
				<div class="form-row">
					<div class="form-col">
						<label for="mail">Adresse courriel :</label>
						<input type="email" id="mail" name="mail" formControlName="email"
							placeholder="exemple@courriel.fr" />
						<div *ngIf="email?.touched && email?.invalid" class="error">
							Vous devez saisir une adresse courriel valide.
						</div>
					</div>
				</div>

				<div class="form-row submit">
					<custom-button color="primary" level="primary" size="large"
						[isDisabled]="forgotPasswordForm.invalid || isSubmitted">Envoyer
					</custom-button>
				</div>
			</form>
		</div>
	</div>
</section>
<app-newsletter-popup *ngIf="!inIframe()"></app-newsletter-popup>
<app-footer *ngIf="!inIframe()"></app-footer>