import { Component, OnInit, Input } from '@angular/core';
import { History } from '@interfaces/history';

@Component({
	selector: 'app-history-box',
	templateUrl: './history-box.component.html',
	styleUrls: ['./history-box.component.scss'],
})
export class HistoryBoxComponent implements OnInit {
	@Input() histories: History[] = [
		{
			action_type: '',
			action_date: '',
			comment: '',
		},
	];

	constructor() {}

	ngOnInit(): void {
		this.histories.sort((a, b) => new Date(b.action_date).getTime() - new Date(a.action_date).getTime());
	}
}
