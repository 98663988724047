<main class="newevent-container">
	<app-page-title title="Édition d'événements" subtitle="Modifier un événement existant" btnText="Dupliquer"
		btnIcon="file-outline" btnColor="secondary" [btnCallBackFunction]="duplicateEvent"></app-page-title>
	<div class="nc-content-wrapper" *ngIf="loading">Chargement...</div>
	<div class="nc-content-wrapper" *ngIf="!loading && (!event)">Une erreur s'est produite...</div>
	<div class="nc-content-wrapper" *ngIf="!loading && event">
		<div class="nccw-main">
			<div class="nccw-step">
				<app-list-title listName="Informations générales" [typeList]="'form'"></app-list-title>
				<div class="nccws-content">
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="name">Nom de l'événement</label>
							<input name="name" type="text" placeholder="Journée portes ouvertes"
								[(ngModel)]="event.name" (change)="updateErrors()" maxlength="100"/>
							<p *ngIf="errors.name" class="nccwsri-error">Vous devez donner un nom à votre événement.</p>
						</div>
						<div class="nccwsr-item">
							<label for="type">Type d'événement</label>
							<select name="type" [(ngModel)]="event.type" (change)="updateErrors()">
								<option value="" disabled selected>Sélectionner</option>
								<option *ngFor="let type of eventTypes" [value]="type">{{ type }}</option>
							</select>
							<p *ngIf="submitting && errors.type" class="nccwsri-error">
								Vous devez sélectionner un type d'événement.
							</p>
						</div>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="complete">
								Toutes les places ont été prises ?
								<label class="switch">
									<input type="checkbox" name="complete" [(ngModel)]="event.complete" />
									<span class="slider round"></span>
								</label>
							</label>
						</div>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="organization_name">Organisation</label>
							<input name="organization_name" type="text" placeholder="Lycée Salengro"
								[(ngModel)]="event.organization_name" (change)="updateErrors()" />
							<p *ngIf="submitting && errors.organization_name" class="nccwsri-error">
								Vous devez renseigner un organisateur.
							</p>
						</div>
						<div class="nccwsr-item">
							<label for="organization_type">Type d'organisation</label>
							<select name="organization_type" [(ngModel)]="event.organization_type"
								(change)="updateErrors()">
								<option value="" disabled selected>Sélectionner</option>
								<option *ngFor="let type of orgaTypes" [value]="type">{{ type }}</option>
							</select>
							<p *ngIf="submitting && errors.organization_type" class="nccwsri-error">
								Vous devez sélectionner un type d'organisateur.
							</p>
						</div>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="currentPartner">Organisations partenaires (facultatif)</label>
							<div class="nccwsri-partner">
								<input name="currentPartner" type="text" placeholder="Conseil départemental"
									[(ngModel)]="currentPartner" (change)="addPartner()" />
								<custom-button color="primary" level="ghost" iconBefore="plus-outline"
									(click)="addPartner()">
									Ajouter</custom-button>
							</div>
							<div *ngIf="event.partners && event.partners.length > 0" class="partners">
								<span *ngFor="let partner of event.partners; let index = index"
									(click)="removePartner(index)">{{partner}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="nccw-step">
				<app-list-title listName="Dates et description" [typeList]="'form'"></app-list-title>
				<div class="nccws-content">
					<div class="nccws-row">
						<div class="nccwsr-item full">
							<label for="description">Description de l'événement</label>
							<textarea name="description" [(ngModel)]="event.description"
								placeholder="Entrez une description..." (ngModelChange)="onEventDescriptionChange()" (change)="updateErrors()" maxlength="2500"></textarea>
								<span id="eventDescriptionCount">{{ eventDescriptionCount }}</span>
							<p *ngIf="submitting && errors.description" class="nccwsri-error">
								Vous devez renseigner une description.
							</p>
						</div>
					</div>
					<div class="nccw-period" *ngFor="let period of eventPeriods; let periodIndex = index">
						<p class="nccw-section-title">
							Jour {{periodIndex + 1}}
							<custom-button color="danger" level="ghost" (click)="removePeriod(periodIndex)"><i
									class="eva eva-trash-2-outline" (change)="updateErrors()"></i></custom-button>
						</p>
						<div class="nccw-section">
							<i>(Heure de Paris, GMT+2)</i>
							<div class="nccws-row">
								<div class="nccwsr-item">
									<label for="start_date">Date de début</label>
									<input name="start_date" type="date" [(ngModel)]="period.startDate"
										placeholder="jj/mm/aaa" (change)="updateErrors()" />
								</div>
								<div class="nccwsr-item">
									<label for="start_time">Heure de début</label>
									<input name="start_time" type="time" [(ngModel)]="period.startTime"
										placeholder="hh:mm" (change)="updateErrors()" />
								</div>
							</div>
							<p *ngIf="submitting && errors.start_date" class="nccwsri-error">
								Vous devez renseigner une date et heure de début de l'événement.
							</p>
							<div class="nccws-row">
								<div class="nccwsr-item">
									<label for="end_date">Date de fin</label>
									<input name="end_date" type="date" [(ngModel)]="period.endDate"
										placeholder="jj/mm/aaa" (change)="updateErrors()" />
								</div>
								<div class="nccwsr-item">
									<label for="end_time">Heure de fin</label>
									<input name="end_time" type="time" [(ngModel)]="period.endTime" placeholder="hh:mm"
										(change)="updateErrors()" />
								</div>
							</div>
							<p *ngIf="submitting && errors.end_date" class="nccwsri-error">
								Vous devez renseigner une date et heure de fin de l'événement.
							</p>

							<div class="nccws-row">
								<div class="nccwsr-item full planning">
									<label>Programme <i>(Facultatif)</i></label>
									<div class="planning">
										<div *ngFor="let plan of period.eventPlans; let index = index" class="schedule">
											<input name="label-{{index}}" type="text" placeholder="Petit-déjeuner"
												[(ngModel)]="plan.label" (change)="updateErrors()" />
											<input name="label-{{index}}" type="time" placeholder="08:30"
												[(ngModel)]="plan.start" (change)="updateErrors()" />
											<input name="label-{{index}}" type="time" placeholder="09:00"
												[(ngModel)]="plan.end" (change)="updateErrors()" />
											<custom-button color="danger" level="ghost"
												(click)="removePlan(index, periodIndex)"><i
													class="eva eva-trash-2-outline"></i></custom-button>
										</div>
										<custom-button color="secondary" level="ghost" iconBefore="plus-outline"
											(click)="addPlan(periodIndex)">
											Ajouter une tranche horaire</custom-button>
									</div>
								</div>
							</div>

							<div class="nccws-row">
								<div class="nccwsr-item full">
									<label for="period_informations">Informations complémentaires</label>
									<textarea name="period_informations" [(ngModel)]="period.description"
										placeholder="Entrez une description" (ngModelChange)="onEventPeriodDescriptionChange(period)" maxlength="255" (change)="updateErrors()"></textarea>
										<span>{{ period.descriptionCount }}</span>
								</div>
							</div>
						</div>
					</div>
					<p *ngIf="submitting && errors.eventPeriods" class="nccwsri-error">
						Vous devez renseigner les informations d'au moins une période.
					</p>
					<custom-button color="neutral" level="ghost" iconBefore="plus-outline" (click)="addPeriod()">
						Ajouter un jour</custom-button>
				</div>
				<app-list-title listName="Lieu et option" [typeList]="'form'"></app-list-title>
				<div class="nccws-content">
					<div class="nccws-row">
						<div class="nccwsr-item full">
							<label for="isEventOnline">
								Votre événement est-il accessible à distance ?
								<label class="switch">
									<input type="checkbox" name="isEventOnline" (change)="eventOnlineChange()"
										[(ngModel)]="isEventOnline" />
									<span class="slider round"></span>
								</label>
							</label>
						</div>
					</div>
					<div class="nccws-row radio" *ngIf="isEventOnline">
						<div class="nccwsr-item">
							<div class="nccwsr-radio">
								<input type="radio" name="eventOnlineRadio" id="hybrid" value="hybrid"
									[(ngModel)]="eventOnlineChoice" />
								<label for="hybrid"> Mon événement est hybride </label>
							</div>
						</div>
						<div class="nccwsr-item" *ngIf="isEventOnline">
							<div class="nccwsr-radio">
								<input type="radio" name="eventOnlineRadio" id="online" value="online"
									[(ngModel)]="eventOnlineChoice" />
								<label for="online"> Mon événement est uniquement en ligne </label>
							</div>
						</div>
					</div>
					<div class="nccws-row online-event-alert" *ngIf="isEventOnline && eventOnlineChoice === 'online'">
						<i class="eva eva-alert-circle"></i>
						<p>
							Attention, lorsque vous créez un événement en ligne, celui-ci <b>n’apparaîtra pas sur la
								carte</b> mais
							sera visible dans la liste des événements.
						</p>
					</div>
					<div class="nccws-row" *ngIf="isEventOnline">
						<div class="nccwsr-item">
							<label for="online_link">Renseignez le lien d'accès à votre événement
								<i>(facultatif)</i></label>
							<input *ngIf="isEventOnline" name="online_link" type="text" (change)="updateErrors()"
								[(ngModel)]="event.online_link" placeholder="https://monlienvisio.fr" />
							<!-- <p *ngIf="submitting && errors.online_link" class="nccwsri-error">
								Vous devez renseigner un lien valide.
							</p> -->
						</div>
					</div>
					<div class="nccws-row" *ngIf="!isEventOnline || (isEventOnline && eventOnlineChoice === 'hybrid')">
						<div class="nccwsr-item">
							<label for="address">Adresse</label>
							<input name="address" type="text" placeholder="20 rue de la gare"
								[(ngModel)]="event.address" (change)="updateErrors()" />
							<p *ngIf="submitting && errors.address" class="nccwsri-error">
								Vous devez renseigner l'adresse du lieu de l'événement.
							</p>
						</div>
					</div>
					<div class="nccws-row" *ngIf="!isEventOnline || (isEventOnline && eventOnlineChoice === 'hybrid')">
						<div class="nccwsr-item">
							<label for="zip_code">Code postal / Ville</label>
							<input name="zip_code" type="text" placeholder="75001 - Paris" [(ngModel)]="event.zip_code"
								(change)="updateErrors()" list="zipcodes" (ngModelChange)="onZipcodeChange($event)"
								autocomplete="off" id="add-zipcode" />
							<datalist id="zipcodes">
								<option *ngFor="let commune of matchingCommunes" [value]=" commune">{{ commune }}
								</option>
							</datalist>
							<i>Le code postal doit être au format "XXXXX - Ville" et correspondre à celui proposé par la liste déroulante.</i>

							<p *ngIf="submitting && errors.zip_code" class="nccwsri-error">
								Vous devez renseigner le code postal du lieu de l'événement.
							</p>

							<p *ngIf="submitting && errors.city" class="nccwsri-error">
								Le code postal et la ville ne font pas partie de la liste prédéfinie
							</p>

							<p *ngIf="submitting && errors.location" class="nccwsri-error">
								L'addresse et le code postal ne semble pas être corrects. Veuillez vérifier vos
								informations et
								réessayer ultérieurement.
							</p>
						</div>
					</div>
					<div class="nccws-row" *ngIf="!isEventOnline || (isEventOnline && eventOnlineChoice === 'hybrid')">
						<div class="nccwsr-item">
							<label for="accessibility">Accessibilité à l'événement</label>
							<select name="accessibility" [(ngModel)]="event.accessibility" (change)="updateErrors()">
								<option value="" disabled selected>Sélectionner</option>
								<option *ngFor="let type of accessTypes" [value]="type">{{ type }}</option>
							</select>
							<p *ngIf="submitting && errors.accessibility" class="nccwsri-error">
								Vous devez sélectionner votre niveau d'accessibilité.
							</p>
						</div>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item">
							<!-- <label for="currentPublic">Public visé</label>
							<div class="nccwsri-partner">
								<input name="currentPublic" type="text" placeholder="Lycée" [(ngModel)]="currentPublic"
									(change)="addPublic()" />
								<custom-button color="primary" level="ghost" iconBefore="plus-outline"
									(click)="addPublic()">
									Ajouter</custom-button>
							</div> -->
							<label for="public">Public visé</label>
							<select name="public" [(ngModel)]="currentPublic" (change)="updatePublic()">
								<option value="" disabled selected>Sélectionner</option>
								<option *ngFor="let type of publicTypes" [value]="type">{{ type }}</option>
							</select>
						</div>
						<div class="nccwsr-item">
							<label for="max_capacity">Jauge (nombre de personnes)</label>
							<input name="max_capacity" type="number" placeholder="40" [(ngModel)]="event.max_capacity"
								(change)="updateErrors()" />
						</div>
					</div>
					<div class="nccws-row">
						<div *ngIf="event.public && event.public.length > 0" class="partners">
							<span *ngFor="let public of event.public; let index = index" (click)="removePublic(index)">
								{{public}}<i class="eva eva-close-outline"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="nccw-step">
				<app-list-title listName="Détails et conditions" [typeList]="'form'"></app-list-title>
				<div class="nccws-content">
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="registration_conditions">Conditions d'inscription</label>
							<select name="registration_conditions" [(ngModel)]="event.registration_conditions"
								(change)="registrationRequiredChange()">
								<option value="" disabled selected>Sélectionner</option>
								<option *ngFor="let condition of eventRegistrationConditions" [value]="condition">{{
									condition }}</option>
							</select>
						</div>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="planner_email">Adresse courriel (facultatif)</label>
							<input name="planner_email" type="email" [(ngModel)]="event.planner_email"
								placeholder="exemple@courriel.fr" />
							<p *ngIf="submitting && errors.planner_email" class="nccwsri-error">
								L'adresse courriel saisie doit être valide.
							</p>
						</div>
						<div class="nccwsr-item">
							<label for="planner_phone">Numéro de téléphone (facultatif)</label>
							<input name="planner_phone" type="tel" [(ngModel)]="event.planner_phone"
								placeholder="01 23 45 67 89" />
							<p *ngIf="submitting && errors.planner_phone" class="nccwsri-error">
								Le numéro de téléphone saisi doit être valide.
							</p>
						</div>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item full">
							<i>Ces coordonnées seront affichées publiquement sur la fiche de l'événement afin de
								permettre aux
								personnes intéressées de vous contacter directement.</i>
						</div>
					</div>
					<div class="nccws-row">
						<p *ngIf="errors.event_registration" class="nccwsri-error">
							Vous devez renseigner les conditions d'inscription. <i>(facultatif)</i>
						</p>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item __image">
							<label class="image-upload-container btn btn-bwm">
								<span class="upload-span">Ajouter une image (format : 1 200 x 500 pixels)</span>
								<input #imageInput type="file" accept="image/jpg, image/jpeg, image/png, image/gif"
									(change)="processFile(imageInput)" />
							</label>

							<div *ngIf="selectedFile" class="img-preview-container">
								<div class="img-preview{{selectedFile.status === 'fail' ? '-error' : ''}}"
									[ngStyle]="{'background-image': 'url('+ selectedFile.src + ')'}"></div>

								<div *ngIf="selectedFile.pending" class="img-loading-overlay">
									<div class="img-spinning-circle"></div>
								</div>

								<div *ngIf="selectedFile.status === 'ok'" class="alert alert-success">Image ajoutée !
								</div>
								<div *ngIf="selectedFile.status === 'fail'" class="alert alert-danger">
									Une erreur s'est produite...
								</div>
							</div>
							<i>Format admis : .jpg, .jpeg, .png ou .gif. Poids: 2Mo</i>
							<p *ngIf="errors.event_img" class="nccwsri-error">Votre image doit correspondre au format
								demandé.</p>
							<ul *ngIf="errors.event_img" class="nccwsri-error">
								<li><strong>Formats admis</strong>: .jpg, .jpeg, .png, .gif</li>
								<li><strong>Poids: </strong>: 2Mo</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="nccw-step">
				<app-list-title listName="Statistiques" [typeList]="'form'"></app-list-title>
				<div class="nccws-content">
					<div class="nccwsr-item full">
						<i> Ces données sont à renseigner une fois l'événement passé. </i>
					</div>
					<div class="nccws-row">
						<div class="nccwsr-item">
							<label for="start_date">Personnes inscrites</label>
							<input name="" type="number" min="0" [max]="event.max_capacity"
								[(ngModel)]="event.registered_persons" placeholder="nombre" />
						</div>
						<div class="nccwsr-item">
							<label for="start_time">Personnes présentes</label>
							<input name="" type="number" min="0" [max]="event.max_capacity"
								[(ngModel)]="event.attendees" placeholder="nombre" />
						</div>
					</div>
					<div class="nccws-row online-event-alert">
						<i class="eva eva-alert-circle"></i>
						<p>
							Attention, il est important d'enregistrer un événement modifié avant de le publier. Sinon
							les
							modifications ne seront pas prises en compte
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="nccw-aside">
			<div class="historic-box">
				<h2 class="hb-title">Historique de suivi</h2>
				<app-history-box [histories]="event.histories"></app-history-box>
				<div class="hb-actions">
					<custom-button color="accent" level="primary" iconBefore="save-outline" (click)="save()">Enregistrer
					</custom-button>
					<custom-button *ngIf="event.status === 'Publié'" color="danger" level="secondary" iconBefore="close"
						(click)="unpublish()">Dépublier
					</custom-button>
					<custom-button
						*ngIf="userService.sessionUser.role == 'Organisateur' && (event.status == 'Brouillon' || event.status == 'Modifié' || event.status == 'Publié')"
						color="success" level="primary" iconBefore="checkmark-outline" (click)="publish()">
						Soumettre les modifications
					</custom-button>
					<div class="admin-actions"
						*ngIf="event.status !== 'Publié' && userService.sessionUser.role !== 'Organisateur'">
						<custom-button color="danger" level="secondary" iconBefore="close"
							(click)="rejectCommentDialog.showModal()">
							Refuser l'événement
						</custom-button>
						<custom-button color="success" level="primary" iconBefore="checkmark-outline"
							(click)="setStatus('publish')">
							Valider l'événement
						</custom-button>
					</div>
				</div>
				<!-- <div class="hb-warning" *ngIf="userService.sessionUser.role == 'Organisateur' && (event.status == 'Publié' || event.status == 'Modifié')">
					<div class="hbw-row edit-published-event">
						<i class="eva eva-alert-circle"></i>
						<p>
							Attention, modifier un événement déjà publié entraînera une demande de validation supplémentaire.
							En cas d'édition, il faudra soumettre les modifications afin qu'elles puissent être prises en compte par les modérateurs.
						</p>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</main>
<dialog appDialog #rejectCommentDialog class="nsi-dialog" [title]="'Refuser un événement'">
	<ng-template appDialogContent>
		<div class="reject-content">
			<textarea name="rejectcomment" class="d-input" placeholder="Ajouter un message de refus"
			[(ngModel)]="statusComment" (ngModelChange)="onEventRejectCommentChange()" maxlength="255"></textarea>
			<span>{{ eventRejectCommentCount }}</span>
		</div>
	</ng-template>

	<ng-template appDialogFooter>
		<custom-button color="danger" level="secondary" iconBefore="close" (click)="setStatus('reject')">
			Refuser l'événement
		</custom-button>
	</ng-template>
</dialog>