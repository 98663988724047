import { Component, Input, OnInit } from '@angular/core';
import { Role } from 'app/enums/role.enum';
import { TypeListTitle } from 'app/enums/types-list-title.enum';
import { UserService } from 'app/services/user.service';

@Component({
	selector: 'app-list-title',
	templateUrl: './list-title.component.html',
	styleUrls: ['./list-title.component.scss'],
})
export class ListTitleComponent implements OnInit {
	admin = false;
	@Input() typeList!: any;
	@Input() listName = 'Tous les événements';

	constructor(private userService: UserService) { }

	ngOnInit(): void {
		this.admin = this.userService.sessionUser.role != Role.ORGANIZER;
	}
}
