<section class="sidebar desktop">
    <div class="s-header">
        <div class="sh-logo">
            <img class="shl-svg" src="assets/logos/nsi.svg" />
        </div>
        <div class="sh-user">
            <p class="shu-role">{{ user.role }}</p>
            <p class="shu-name">{{ user.first_name }} {{ user.last_name }}</p>
        </div>
    </div>
    <ul class="s-nav">
        <li class="sn-item" routerLink="/events">Événements</li>
        <li class="sn-item" routerLink="/events-2022">Edition 2022</li>
        <li class="sn-item" routerLink="/users" *ngIf="user.role.toLowerCase() == 'administrateur'">Utilisateurs</li>
        <li class="sn-item" routerLink="/settings">Paramètres</li>
    </ul>
    <div class="s-disconnect">
        <custom-button class="sd-btn" color="primary" level="ghost" iconBefore="log-out-outline" (click)="logout()">Se
            déconnecter
        </custom-button>
    </div>
</section>
<section class="sidebar mobile">
    <div class="sm-container">
        <div routerLink="/events" routerLinkActive="active" class="sm-item">
            <i class="eva eva-calendar smi-icon"></i>
            <span class="smi-text">Événements</span>
        </div>
        <div routerLink="/users" routerLinkActive="active" class="sm-item"
            *ngIf="user.role.toLowerCase() == 'administrateur'">
            <i class="eva eva-people smi-icon"></i>
            <span class="smi-text">Utilisateurs</span>
        </div>
        <div routerLink="/settings" routerLinkActive="active" class="sm-item">
            <i class="eva eva-settings smi-icon"></i>
            <span class="smi-text">Paramètres</span>
        </div>
        <div (click)="logout()" class="sm-disconect">
            <i class="eva eva-log-out smi-icon"></i>
        </div>
    </div>
</section>