import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'app/services/toaster.service';
import { environment } from 'environments/environment';
import { ConfirmedValidator } from '../register/confirmed.validators';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.page.html',
	styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit {
	illuContent: any;
	submitting = false;
	token!: string;
	userId!: string;
	changePasswordForm!: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private httpClient: HttpClient,
		private formbuilder: FormBuilder,
		private toasterService: ToasterService,
	) {
		this.illuContent = this.illuContent = {
			image1: 'assets/images/hero-1.jpg',
			border: '#F09E2A',
			fill: '#F09E2A',
		};

		this.activatedRoute.queryParams.subscribe((params) => {
			this.token = params['token'];
			this.userId = params['id'];
		});
	}

	ngOnInit(): void {
		this.changePasswordForm = this.formbuilder.group(
			{
				newPassword: ['', [Validators.required, Validators.minLength(6)]],
				confirmPassword: ['', [Validators.required]],
			},
			{
				validator: ConfirmedValidator('newPassword', 'confirmPassword'),
			},
		);
	}

	onSubmit = () => {
		const formValue = this.changePasswordForm.value;

		this.httpClient
			.post(`${environment.apiUrl}/auth/change-password`, {
				password: formValue['newPassword'],
				userId: this.userId,
				token: this.token,
			})
			.subscribe();
		this.toasterService.showToast('Votre mot de passe  a bien été modifié', 'Félicitations', 'success');
		setTimeout(() => {
			this.returnToLogin();
		}, 2650);
	};

	returnToLogin = () => {
		this.router.navigate(['/signin']);
	};

	get newPassword() {
		return this.changePasswordForm.get('newPassword');
	}
	get confirmPassword() {
		return this.changePasswordForm.get('confirmPassword');
	}
}
