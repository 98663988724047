<app-navbar></app-navbar>
<section class="hero-section" style="background-color: rgba(0, 0, 0, 0.05)">
	<div class="container">
		<div class="hs-images-container">
			<app-masked-illustration [illuContent]="illuContent"></app-masked-illustration>
		</div>
		<div class="hs-content">
			<div class="hsc-titles">
				<h1 class="hsct-title">Se connecter</h1>
				<p class="hsct-subtitle">Accédez à votre espace personnel pour gérer vos événements</p>
			</div>
			<div class="hsc-info" *ngIf="signedUp && !postError">
				Votre inscription a réussi, veuillez vous connecter à l'aide de vos identifiants.
			</div>
			<div class="hsc-error" *ngIf="postError">
				Nous n'avons pas pu vous connecter. Veuillez vérifier vos identifiants et essayer à nouveau.
			</div>
			<form class="hsc-form" [formGroup]="signInForm" (ngSubmit)="postForm()">
				<div class="form-row">
					<div class="form-col">
						<label for="email">Adresse courriel :</label>
						<input type="email" id="email" name="email" formControlName="email" placeholder="exemple@courriel.fr" />
						<div *ngIf="email?.invalid && (email?.touched)" class="error">
							Vous devez saisir une adresse courriel valide.
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-col">
						<label for="password">Mot de passe :</label>
						<input
							type="password"
							id="password"
							name="password"
							formControlName="password"
							placeholder="Votre mot de passe" />
						<div *ngIf="password?.invalid && ( password?.touched)" class="error">
							Vous devez saisir un mot de passe.
						</div>
					</div>
				</div>

				<a [routerLink]="['/forgot-password']" class="forget-password">Mot de passe oublié ?</a>

				<div class="form-row submit">
					<custom-button
						color="primary"
						level="primary"
						size="large"
						[isDisabled]="signInForm.invalid"
						*ngIf="!submitting"
						>Connexion
					</custom-button>
					<custom-button color="primary" level="ghost" size="large" *ngIf="submitting">Chargement... </custom-button>
				</div>

				<span class="account-creation"
					>Vous n'avez pas de compte ? <a [routerLink]="['/signup']">Créez-en un !</a></span
				>
			</form>
		</div>
	</div>
</section>
<app-footer></app-footer>
