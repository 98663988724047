import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInstagram, faFacebook, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CustomButtonParticle } from './particles/custom-button/custom-button.particle';
import { EventsPage } from './pages/events/events.page';
import { Events2022Page } from './pages/events-2022/events-2022.page';
import { UsersPage } from './pages/users/users.page';
import { SettingsPage } from './pages/settings/settings.page';
import { PreviewPage } from './pages/preview/preview.page';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { AuthInterceptor } from './interceptors/jwt.interceptor';
import { ListTitleComponent } from './components/list-title/list-title.component';
import { NewEventPage } from './pages/newevent/newevent.page';
import { FormSectionTitleComponent } from './components/form-section-title/form-section-title.component';
import { LoginPage } from './pages/login/login.page';
import { MainTemplate } from './templates/main/main.template';
import { MaskedIllustrationComponent } from './components/masked-illustration/masked-illustration.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NewsletterPopupComponent } from './components/newsletter-popup/newsletter-popup.component';
import { FooterComponent } from './components/footer/footer.component';
import { RegisterPage } from './pages/register/register.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { EditEventPage } from './pages/editevent/editevent.page';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserProfilePage } from './pages/user-profile/user-profile.page';
import { CustomSelectParticle } from './particles/custom-select/custom-select.particle';
import { TabTitleComponent } from './components/tab-title/tab-title.component';
import { ChangePasswordPage } from './pages/change-password/change-password.page';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Scroll } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ModalComponent } from './components/modal/modal.component';
import { HistoryBoxComponent } from './components/history-box/history-box.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogContentDirective } from './directives/dialog-content.directive';
import { DialogFooterDirective } from './directives/dialog-footer.directive';
import { DialogHeaderDirective } from './directives/dialog-header.directive';


@NgModule({
	declarations: [
		AppComponent,
		SidebarComponent,
		CustomButtonParticle,
		CustomSelectParticle,
		EventsPage,
		Events2022Page,
		UsersPage,
		SettingsPage,
		PageTitleComponent,
		EventCardComponent,
		PreviewPage,
		UserCardComponent,
		ListTitleComponent,
		NewEventPage,
		EditEventPage,
		FormSectionTitleComponent,
		LoginPage,
		UserProfilePage,
		MainTemplate,
		MaskedIllustrationComponent,
		NavbarComponent,
		NewsletterPopupComponent,
		FooterComponent,
		RegisterPage,
		ForgotPasswordPage,
		ChangePasswordPage,
		TabTitleComponent,
		ModalComponent,
		HistoryBoxComponent,
		DialogComponent,
		DialogFooterDirective,
		DialogHeaderDirective,
		DialogContentDirective,
	],
	imports: [
		BrowserModule,
		FontAwesomeModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		ScrollingModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{
			provide: 'externalUrlRedirectResolver',
			useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
				window.location.href = (route.data as any).externalUrl;
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faInstagram, faFacebook, faLinkedin, faTwitter, faYoutube, faXmark, faMagnifyingGlass);
	}
}
