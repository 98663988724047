<div class="list-title">
	<div class="lt-container">
		<p class="ltc-name">
			{{ listName }}
		</p>

		<ng-template *ngIf="typeList == 'events'">
			<p class="ltc-item ltc-orga" *ngIf="admin">Organisateur</p>
			<p class="ltc-item ltc-status">Statut</p>
			<p class="ltc-item ltc-date">Date</p>
			<p class="ltc-item ltc-actions">Actions</p>
		</ng-template>
		<ng-template *ngIf="typeList !== 'events' && typeList !== 'form'">
			<p class="ltc-item ltc-actions">Événements</p>
			<p class="ltc-item ltc-actions">Role</p>
			<p class="ltc-item ltc-actions">Actions</p>
		</ng-template>
	</div>
</div>