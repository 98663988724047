import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@interfaces/user.interface';
import { UserService } from 'app/services/user.service';
import { removeToken } from '../../utils/jwt-decode';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  user!: User;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.user = this.userService.sessionUser;
  }

  logout(): any {
    removeToken();
    this.router.navigateByUrl('/signin');
  }

}
