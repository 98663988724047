import { User } from '@interfaces/user.interface';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export const isTokenOK = (token: string) => {
	let decryptedToken: JwtPayload = jwt_decode(token);
	if (jwt_decode(token)) {
		if (decryptedToken.exp && Date.now() <= decryptedToken.exp * 1000) {
			return true;
		}
		return false;
	}
	return false;
};

export const getUserFromToken = (token: string): User => {
	return jwt_decode(token);
};

export const removeToken = () => {
	localStorage.removeItem('token');
};
