import { Component, OnInit } from '@angular/core';
import { User } from '@interfaces/user.interface';
import { TypeListTitle } from 'app/enums/types-list-title.enum';
import { UserService } from 'app/services/user.service';
import { getUserFromToken } from 'app/utils/jwt-decode';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-users',
	templateUrl: './users.page.html',
	styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit {
	listType: TypeListTitle = TypeListTitle.USERS;
	allUsers!: User[];
	usersSubscription!: Subscription;

	userLogged!: User;

	constructor(private userService: UserService) { }

	sortByLastName(users: User[]) {
		users.sort((a, b) => a.last_name.localeCompare(b.last_name))
	}

	async ngOnInit() {
		const token = localStorage.getItem('token');
		if (token) this.userLogged = getUserFromToken(token);
		await this.userService.getAllUsers();
		this.allUsers = this.userService.allUsers.filter((user) => user.id != this.userLogged.id);
		this.usersSubscription = this.userService.usersSubject.subscribe(
			(users) => (this.allUsers = users.filter((elt) => elt.id != this.userLogged.id)),
		);
		this.sortByLastName(this.allUsers)
	}
}
