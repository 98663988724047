import { Component, OnInit } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Link, Social } from 'app/interfaces/collections.interface';
import { Footer } from 'app/interfaces/components.interface';
import { CollectionsService } from 'app/services/collections.service';
import { ComponentsService } from 'app/services/components.service';
import { NewsletterService } from 'app/services/newsletter.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	imageUrl: string;
	footerDatas!: Footer;
	links!: Link[];
	socialTitle!: string;
	alertsTitle!: string;
	alertsButton!: string;
	socials!: Social[];
	instagram: IconName = 'instagram';
	facebook: IconName = 'facebook';
	linkedin: IconName = 'linkedin';
	youtube: IconName = 'youtube';
	constructor(private newsletterService: NewsletterService, private componentsService: ComponentsService, private collectionsService: CollectionsService) {
		this.imageUrl = environment.imagesURL;

	}

	ngOnInit(): void {
		this.getDatas();
	}

	async getDatas(): Promise<Footer> {
		this.socials = await this.collectionsService.getAllSocials();
		const rawDatas = await this.componentsService.getAllDatasOfSinglePage(
			'footer',
			'error to fetch home datas',
		);
		return this.footerDatas = await this.componentsService.formatFooter(rawDatas);

	}

	newsLetterPopupOpen = () => {
		this.newsletterService.openPopup();
	};
}
