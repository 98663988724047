<div class="newsletter-popup" [ngClass]="isNewsletterPopUpOpen ? 'popupOpened' : ''"
	[attr.aria-hidden]="isNewsletterPopUpOpen ? 'false' : 'true'">
	<div class="np-dialog">
		<fa-icon class="npd-close" [icon]="['fas', 'xmark']" (click)="closeNewsLetterPopup()"></fa-icon>
		<div class="npd-texts-form">
			<div class="npdtf-header section-header">
				<div class="npdtfh-slash sh-slash"></div>
				<div class="npdtfh-title-subtitle-wrapper sh-title-wrapper">
					<h3 class="npdtfhtsw-title shtw-title">S'abonner aux alertes</h3>
					<p class="npdtfhtsw-subtitle shtw-subtitle" *ngIf="weekDatesStartEnd">
						Vous souhaitez suivre les événements organisés du {{ eventDatesStringifyed }}
						{{ weekDatesStartEnd.startDate.date | date: 'yyyy' }} ? Inscrivez-vous pour recevoir les
						notifications.
					</p>
				</div>
			</div>
			<form class="npd-form" [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
				<div class="npdf-field">
					<label class="npdff-label" for="add-email">Ajouter un email</label>
					<input class="npdff-input" id="add-email" formControlName="email" type="email"
						placeholder="Votre email" />
				</div>
				<div class="npdf-field">
					<label class="npdff-label" for="add-department">Département</label>

					<input class="npdff-select" formControlName="departments" type="text" list="departments"
						id="add-department" />
					<datalist id="departments">
						<option *ngFor="let department of allDepartments" [value]="department.num_dep">
							{{ department.num_dep }} - {{ department.dep_name }}
						</option>
					</datalist>

					<div class="npdff-results-wrapper">
						<div class="npdffrw-result" *ngFor="let chosen of chosenDepartmentsDisplayed">
							<span>{{ chosen.dep_name }}</span><fa-icon class="npdffrwr-icon" [icon]="['fas', 'xmark']"
								(click)="unChooseDepartment(chosen.num_dep)"></fa-icon>
						</div>
					</div>
				</div>
				<div class="npdf-field">
					<p class="npdff-title" for="add-email">
						Vous souhaitez recevoir un résumé chaque semaine des nouveaux événements publiés sur semaine-nsi.fr ? Merci de préciser vos choix :
					</p>
					<div class="npdff-checkbox-wrapper">
						<input class="npdffcw-checkbox" type="checkbox" formControlName="notifiedOnDepartmentsEvents"
							name="notifiedOnDepartmentsEvents" id="notifiedOnDepartmentsEvents" />
						<label class="npdffcw-checkbox" for="notifiedOnDepartmentsEvents">
							Je souhaite être notifié(e) lors de nouveaux événements dans les départements sélectionnés.
						</label>
					</div>
					<div class="npdff-checkbox-wrapper">
						<input class="npdffcw-checkbox" type="checkbox" formControlName="notifiedOnOnlineEvents"
							name="notifiedOnOnlineEvents" id="notifiedOnOnlineEvents" />
						<label class="npdffcw-checkbox" for="notifiedOnOnlineEvents">
							Je souhaite être notifié(e) lors de nouveaux événements en ligne.
						</label>
					</div>
					<div class="npdff-checkbox-wrapper agreed">
						<input class="npdffcw-checkbox" type="checkbox" formControlName="agreed" name="agreed"
							id="agreed" />
						<label class="npdffcw-checkbox" for="agreed">J’accepte de recevoir des courriels à propos de
							l’organisation de la semaine NSI.</label>
					</div>
				</div>
				<custom-button [isDisabled]="newsletterForm.invalid || !isAtLeastOneCheckBoxChecked">S'inscrire aux
					alertes</custom-button>
			</form>
		</div>
		<div class="npd-image-container">
			<img class="npdic-image" src="./assets/svg/newsletter.svg" alt="" />
		</div>
	</div>
</div>