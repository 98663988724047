import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'app/services/toaster.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.page.html',
	styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
	illuContent: any;
	error: any = {};
	submitting = false;
	isSubmitted = false;

	forgotPasswordForm!: FormGroup;

	constructor(
		private httpClient: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private toasterService: ToasterService,
	) {}

	ngOnInit(): void {
		this.illuContent = {
			image1: 'assets/images/hero-1.jpg',
			border: '#F09E2A',
			fill: '#BA3C12',
		};

		this.forgotPasswordForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	postForm = () => {
		this.submitting = true;
		const formValue = this.forgotPasswordForm.value;
		this.httpClient.post(`${environment.apiUrl}/auth/reset-password`, formValue).subscribe();
		this.toasterService.showToast('Votre demande a bien été prise en compte', '', 'success');
		this.isSubmitted = true;
	};

	get email() {
		return this.forgotPasswordForm.get('email');
	}

	inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	returnToLogin = () => {
		this.router.navigate(['/signin']);
	};
}
