import { Component, OnInit } from '@angular/core';
import { EventService } from 'app/services/event.service';
import { environment } from '../../../environments/environment';

interface NavBarItemInterface {
	text: string;
	link: string;
}

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	isNavMobileOpen?: boolean = false;
	isNavSticky: boolean = false;
	environment: any = environment;

	constructor(public eventService: EventService) { }

	navBarItems: NavBarItemInterface[] = [
		{
			text: 'comment participer ?',
			link: '/comment-participer'
		},
		{
			text: 'Devenir Partenaire',
			link: '/contact',
		},
		{
			text: 'a propos',
			link: '/a-propos',
		},
		{
			text: 'contact',
			link: '/contact',
		},
	];

	ngOnInit(): void {
		window.addEventListener('scroll', this.scroll, true);
		this.eventService.getAllPublishedEventsFromAPI();
	}

	toggleNavMobile() {
		this.isNavMobileOpen = !this.isNavMobileOpen;
	}

	closeNavMobile() {
		this.isNavMobileOpen = false;
	}

	scroll = (): void => {
		if (window.scrollY >= 100) {
			this.isNavSticky = true;
		} else if (window.scrollY < 100) {
			this.isNavSticky = false;
		}
	};
}
