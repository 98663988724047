<div class="hb-history" *ngIf="histories.length > 0; else noHistory">
    <div *ngFor="let changes of histories.slice(0,5); let last = last" class="hbh-change" [class.last-item]="last">
        <p class="hbhc-name">
            <span class="change-name">{{changes.action_type}}</span>
            <span class="change-date">{{changes.action_date | date: 'dd-MM-YY'}}</span>
        </p>
        <p class="hbhc-comment" *ngIf="changes.comment">{{changes.comment}}</p>
    </div>
</div>
<ng-template #noHistory>
    <div class="hb-history">
        <p class="hbh-nohistory">Aucun historique disponible pour le moment</p>
    </div>
</ng-template>