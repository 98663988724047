<form class="settings-container" [formGroup]="editUserForm" (ngSubmit)="onSave()">
	<app-page-title
		title="Édition du profil"
		subtitle="Modifier votre profil"
		btnColor="success"
		btnIcon="save-outline"
		btnText="Sauvegarder"
		[buttonSubmit]="true"
		[isButtonDisabled]="password?.touched && (password?.errors?.required || f.password.errors?.minlength || f.confirmPassword.errors?.confirmedValidator)"
		[DeleteBtn]="true">
	</app-page-title>
	<div class="parameters-container">
		<app-tab-title (activeItemName)="getTemplateName($event)"></app-tab-title>
		<div class="pc-section" *ngIf="userLogged">
			<ng-template [ngIf]="template == 'myInformations'">
				<div class="pcs-form user-form">
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="first_name" class="pcsfri-label">Prénom</label>
							<input type="text" name="first_name" id="first_name" formControlName="first_name" class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="last_name" class="pcsfri-label">Nom</label>
							<input type="text" name="last_name" id="last_name" formControlName="last_name" class="pcsfri-input" />
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="job" class="pcsfri-label">Fonction</label>
							<input type="text" name="job" id="job" formControlName="job" class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="phone" class="pcsfri-label">Téléphone</label>
							<input type="text" name="phone" id="phone" formControlName="phone" class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="mail" class="pcsfri-label">Adresse email</label>
							<input type="email" name="mail" id="mail" formControlName="mail" class="pcsfri-input" />
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="organization_name" class="pcsfri-label">Organisation</label>
							<input
								type="text"
								name="organization_name"
								id="organization_name"
								formControlName="organization_name"
								class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="organization_type" class="pcsfri-label">Type de structure :</label>
							<!-- <input type="text" name="organization_type" id="organization_type"
								formControlName="organization_type" /> -->
							<select name="organization_type" formControlName="organization_type" class="pcsfri-input select">
								<option value="" disabled selected>Sélectionner</option>
								<option *ngFor="let type of orgaTypes" [value]="type">{{ type }}</option>
							</select>
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="password" class="pcsfri-label">Mot de passe : </label>
							<input
								type="password"
								name="password"
								id="password"
								formControlName="password"
								class="pcsfri-input"
								autocomplete="new-password" />
							<span class="pcsfri-error" *ngIf="password?.errors?.required && ( password?.touched)"
								>veuillez entrer votre mot de passe ou un nouveau mot de passe.</span
							>
							<span class="pcsfri-error" *ngIf="f.password.errors?.minlength"
								>votre mot de passe doit comporter au moins 6 caractères.</span
							>
						</div>
						<div class="pcsfr-item">
							<label for="confirmPassword" class="pcsfri-label">Répéter le mot de passe : </label>
							<input
								type="password"
								name="confirmPassword"
								id="confirmPassword"
								formControlName="confirmPassword"
								class="pcsfri-input"
								autocomplete="new-password" />
							<span class="pcsfri-error" *ngIf="f.confirmPassword.errors?.confirmedValidator"
								>Le mot de passe doit correspondre.</span
							>
						</div>
					</div>
				</div>
			</ng-template>
			<!-- <ng-template [ngIf]="template == 'organism'">
				<div class="pcs-form organism-form">
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="organization_name" class="pcsfri-label">Organisation</label>
							<input
								type="text"
								name="organization_name"
								id="organization_name"
								formControlName="organization_name"
								class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="organization_type" class="pcsfri-label">Type de structure :</label>
							<input
								type="text"
								name="organization_type"
								id="organization_type"
								formControlName="organization_type"
								class="pcsfri-input" />
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="legal_status" class="pcsfri-label">Status juridique :</label>
							<input
								type="text"
								name="legal_status"
								id="legal_status"
								formControlName="legal_status"
								class="pcsfri-input" />
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="organization_logo" class="pcsfri-label">Votre logo :</label>
							<input
								#imageInput
								type="file"
								name="organization_logo"
								id="organization_logo"
								accept="image/*"
								formControlName="organization_logo"
								class="pcsfri-input"
								(change)="onFileChange($event)" />
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="address" class="pcsfri-label">Adresse postale</label>
							<input type="text" name="address" id="address" formControlName="address" class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="zip_code" class="pcsfri-label">Code postal</label>
							<input type="text" name="zip_code" id="zip_code" formControlName="zip_code" class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="department" class="pcsfri-label">Département</label>
							<input type="text" name="department" id="department" formControlName="department" class="pcsfri-input" />
						</div>
						<div class="pcsfr-item">
							<label for="city" class="pcsfri-label">Ville</label>
							<input type="text" name="city" id="city" formControlName="city" class="pcsfri-input" />
						</div>
					</div>
					<div class="pcsf-row">
						<div class="pcsfr-item">
							<label for="website_link" class="pcsfri-label">Lien de votre site internet</label>
							<input type="text" name="website_link" id="website_link" class="pcsfri-input" />
						</div>
					</div>
				</div>
			</ng-template> -->
			<ng-template [ngIf]="template == 'alerts'">
				<div class="pcs-form alerts-form">
					<div class="pcsf-row">
						<div class="pcsfr-item alerts">
							<label for="department" class="pcsfri-label">Départements</label>
							<input
								type="text"
								name="department"
								id="department"
								list="departments"
								formControlName="alertDepartments"
								class="pcsfri-input alerts" />
							<datalist id="departments">
								<option *ngFor="let department of allDepartments" [value]="department.num_dep">
									{{ department.num_dep }} - {{ department.dep_name }}
								</option>
							</datalist>
							<div class="pcsfri-results-wrapper">
								<div class="pcsfrirw-result" *ngFor="let chosen of chosenDepartmentsDisplayed">
									<span>{{ chosen.dep_name }}</span>
									<fa-icon
										class="pcsfrirwr-icon"
										[icon]="['fas', 'xmark']"
										(click)="unChooseDepartment(chosen.num_dep)"></fa-icon>
								</div>
							</div>
						</div>
					</div>
					<div class="pcsf-row checkbox-container">
						<p class="pcsfr-title" for="add-email">
							Vous souhaitez recevoir un résumé chaque semaine des nouveaux événements publiés sur semaine-nsi.fr ?
							Merci de préciser vos choix :
						</p>
						<div class="pcsfr-checkbox-wrapper">
							<input
								class="pcsfrcw-checkbox"
								type="checkbox"
								formControlName="notifiedOnDepartmentsEvents"
								name="notifiedOnDepartmentsEvents"
								id="notifiedOnDepartmentsEvents" />
							<label class="pcsfrcw-checkbox" for="notifiedOnDepartmentsEvents"
								>Je souhaite être notifié(e) lors de nouveaux événements dans les départements sélectionnés.</label
							>
						</div>
						<div class="pcsfr-checkbox-wrapper">
							<input
								class="pcsfrcw-checkbox"
								type="checkbox"
								formControlName="notifiedOnOnlineEvents"
								name="notifiedOnOnlineEvents"
								id="notifiedOnOnlineEvents" />
							<label class="pcsfrcw-checkbox" for="notifiedOnOnlineEvents"
								>Je souhaite être notifié(e) lors de nouveaux événements en ligne</label
							>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</form>
