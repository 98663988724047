import { Component, OnInit, Input } from '@angular/core';

export enum ButtonSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

export enum ColorButton {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	ACCENT = 'accent',
	NEUTRAL = 'neutral',
	SUCCESS = 'success',
}

export enum ButtonLevel {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	GHOST = 'ghost',
}

@Component({
	selector: 'custom-button',
	templateUrl: './custom-button.particle.html',
	styleUrls: ['./custom-button.particle.scss'],
})
export class CustomButtonParticle implements OnInit {
	@Input() color?: string;
	@Input() level?: string;
	@Input() size?: string;
	@Input() iconBefore?: string;
	@Input() iconAfter?: string;
	@Input() callBackFunctionOnClick?: (args?: any) => void;
	@Input() type?: string = 'submit';
	@Input() isDisabled = false;

	colorButton?: ColorButton;
	levelButton?: ButtonLevel;
	sizeButton?: ButtonSize;

	ngOnInit(): void {
		switch (this.color) {
			case 'accent':
				this.colorButton = ColorButton.ACCENT;
				break;
			case 'secondary':
				this.colorButton = ColorButton.SECONDARY;
				break;
			case 'primary':
				this.colorButton = ColorButton.PRIMARY;
				break;
			case 'neutral':
				this.colorButton = ColorButton.NEUTRAL;
				break;
			case 'success':
				this.colorButton = ColorButton.SUCCESS;
				break;
			default:
				this.colorButton = ColorButton.PRIMARY;
		}

		switch (this.level) {
			case ButtonLevel.SECONDARY:
				this.levelButton = ButtonLevel.SECONDARY;
				break;
			case ButtonLevel.GHOST:
				this.levelButton = ButtonLevel.GHOST;
				break;
			default:
				this.levelButton = ButtonLevel.PRIMARY;
		}

		switch (this.size) {
			case ButtonSize.SMALL:
				this.sizeButton = ButtonSize.SMALL;
				break;
			case ButtonSize.MEDIUM:
				this.sizeButton = ButtonSize.MEDIUM;
				break;
			case ButtonSize.LARGE:
				this.sizeButton = ButtonSize.LARGE;
				break;
			default:
				this.sizeButton = ButtonSize.MEDIUM;
		}
	}

	onClick() {
		if (this.callBackFunctionOnClick) this.callBackFunctionOnClick();
	}
}
