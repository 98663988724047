<div class="user-card-wrapper" [ngStyle]="{ display: 'flex' }">
	<p class="ucw-name" [routerLink]="'/user/' + user.id">{{ user.last_name }} {{ user.first_name }}</p>
	<p class="ucw-events">{{ user?.events?.length }} événements</p>
	<form [formGroup]="roleForm">
		<select class="ucw-role" formControlName="role" name="role" id="role">
			<option class="ucwr-option" value="Administrateur">Administrateur</option>
			<option class="ucwr-option" value="Organisateur">Organisateur</option>
			<option class="ucwr-option" value="Moderateur">Moderateur</option>
		</select>
	</form>
	<div class="ucw-buttons" [ngClass]="isConfirmBoxOpen ? 'inactive' : ''">
		<i class="ucwb-icon eva eva-trash-2-outline trash" (click)="onDelete()"> </i>
	</div>
	<div class="ucw-confirmation" [ngClass]="isConfirmBoxOpen ? 'active' : ''">
		<i class="ucwb-icon eva eva-close-outline trash" (click)="onIsConfirmed(false)"></i>
		<i class="ucwb-icon eva eva-checkmark-outline confirm" (click)="onIsConfirmed(true)"></i>
	</div>
</div>