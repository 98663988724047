import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Event } from 'app/interfaces/event.interface';
import { EventService } from 'app/services/event.service';
import { UserService } from 'app/services/user.service';

@Component({
	selector: 'app-event-card',
	templateUrl: './event-card.component.html',
	styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
	admin: boolean = false;
	@Input() validate: boolean = true;
	@Input() event!: any;
	@Input() selected!: boolean;
	@Input() role!: string;
	@Input() editable: boolean = true;
	@Input() checkboxes: boolean = false;
	@Input() add?: (args?: any) => void;
	@Input() remove?: (args?: any) => void;
	@Output() eventStatusChanged = new EventEmitter<string>();
	userRole: string = '';

	modalHistory: boolean = false;

	constructor(private eventService: EventService, private userService: UserService, private router: Router) { }

	ngOnInit(): void {
		this.admin = this.userService.sessionUser.role !== 'Organisateur';
		this.userRole = this.userService.sessionUser.role;
	}

	removeAccents(str: string) {
		return str
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase()
			.replace(' ', '-');
	}

	setStatus(str: string) {
		this.eventService.setEventStatus(this.event.id || '', str).then((data) => {
			this.event = data;
			this.eventStatusChanged.emit(str);
		});
	}

	changeCheck() {
		this.selected = !this.selected;

		this.event.selected = this.selected;
	}

	showHistory() {
		this.modalHistory = !this.modalHistory;
	}
}
