<article class="event-card">
    <input *ngIf="admin && checkboxes" type="checkbox" name="event" (change)="changeCheck()" />
    <div class="ec-data-group name">
        <p class="ecdg-name">{{ event.name }}</p>
    </div>
    <div class="ec-data-group" *ngIf="admin">
        <span class="ecdg-label">Organisateur</span>
        <p [routerLink]="'/user/' + event.user?.id" class="ecdg-orga">{{ event.user?.first_name }} {{
            event.user?.last_name }}</p>
    </div>
    <div class="ec-data-group">
        <span class="ecdg-label">Statut</span>
        <p class="ecdg-status {{removeAccents(event.status || '')}}">{{ event.status}}</p>
    </div>
    <div class="ec-data-group">
        <span class="ecdg-label">Date</span>
        <p class="ecdg-date">{{ event.start_date | date: 'dd/MM/yyyy'}}</p>
    </div>
    <div class="ec-actions" *ngIf="validate else eventActions">
        <i class="eva eva-edit eca-edit"
            *ngIf="editable && (userRole != 'Moderateur' && removeAccents(event.status || '') != 'en-attente')"
            [routerLink]="['/editevent', event.id]" title="Editer l'événement"></i>
        <a href="mailto:{{event.user?.mail}}" *ngIf="admin"><i class="eva eva-email eca-email"
                title="Contacter l'auteur de l'événement"></i></a>
        <i class="eva eva-eye eca-view" [routerLink]="['/preview', event.id]" title="Prévisualiser l'événement"></i>
        <i class="eva eva-clock-outline eca-clock-outline" (click)="historyDialog.showModal()"
            title="Voir l'historique de l'événement"></i>
    </div>
    <ng-template #eventActions>
        <div class="ec-actions">
            <a href="mailto:{{event.user?.mail}}" *ngIf="admin"><i class="eva eva-email eca-email"
                    title="Contacter l'auteur de l'événement"></i></a>
            <i class="eva eva-edit eca-edit"
                *ngIf="editable && (userRole != 'Moderateur' && (removeAccents(event.status || '') == 'en-attente' || removeAccents(event.status || '') == 'publie'))"
                [routerLink]="['/editevent', event.id]" title="Editer l'événement"></i>
            <i class="eva eva-eye eca-view" [routerLink]="['/preview', event.id]" title="Prévisualiser l'événement"></i>
            <i class="eva eva-clock-outline eca-clock-outline" (click)="historyDialog.showModal()"
                title="Voir l'historique de l'événement"></i>
        </div>
    </ng-template>
</article>
<dialog appDialog #historyDialog class="nsi-dialog" [title]="'Historiques d\'actions'">
    <ng-template appDialogContent>
        <app-history-box *ngIf="event.histories.length > 0" [histories]="event.histories"></app-history-box>
    </ng-template>
</dialog>