import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Event } from 'app/interfaces/event.interface';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { ToasterService } from './toaster.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Commune } from '@interfaces/commune';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	allEvents: Event[] = [];
	allPublishedEvents: Event[] = [];
	eventSubject = new Subject<Event[]>();
	httpOptions: any;

	emitEventSubject() {
		this.eventSubject.next(this.allEvents);
	}

	constructor(
		private httpClient: HttpClient,
		private toasterService: ToasterService,
		private router: Router,
		private userService: UserService,
	) {
		this.httpOptions = {
			headers: new HttpHeaders({
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json',
			}),
		};
	}

	async getAllEventsFromAPI() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/all`, this.httpOptions).toPromise();
		return response;
	}

	async getAllEventsFromYear(year: string) {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/all`, this.httpOptions).toPromise();
		let eventFromYear: any[] = [];
		response.forEach((event: any) => {
			if (event.eventDates?.length > 0) {
				let isInYear = event.eventDates.some((period: any) => {
					let eventYear = new Date(period.startDate).getFullYear().toString();
					return eventYear === year;
				})

				if (isInYear) eventFromYear.push(event);
			}
		});
		this.allEvents = eventFromYear;
		this.emitEventSubject();
	}

	async getAllEventsFrom2022() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/all`, this.httpOptions).toPromise();
		let filteredByYearResponse = response.filter((event: any) => {
			let eventYear = new Date(event.start_date).getFullYear().toString();
			return eventYear == "2022";
		});

		this.allEvents = filteredByYearResponse;
		this.emitEventSubject();
	}

	async getAllCommunes() {
		let communes = [];
		const response: any = await this.httpClient.get(`${environment.apiUrl}/communes`, this.httpOptions).toPromise();
		if (response) {
			communes = response;
		}
		return communes;
	}

	async getAllPublishedEventsFromAPI() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events`).toPromise();
		this.allPublishedEvents = response;
		this.emitEventSubject();
	}

	getUnpublishedEventsFromAPI(): Event[] {
		this.emitEventSubject();
		return this.allEvents.filter((el) => {
			if (el.user?.id && el.user.id.length > 0) {
				return el.status === 'Brouillon' && el.user.id === this.userService.sessionUser.id;
			} else {
				return el.status !== 'Publié';
			}
		});
	}

	getPendingEventsFromAPI(): Event[] {
		this.emitEventSubject();
		return this.allEvents.filter((el) => el.status === 'En Attente');
	}

	getModifiedEventsFromAPI(): Event[] {
		this.emitEventSubject();
		return this.allEvents.filter((el) => el.status === 'Modifié');
	}

	getPublishedEventsFromAPI(): Event[] {
		this.emitEventSubject();
		return this.allEvents.filter((el) => el.status === 'Publié' || el.status === 'Rejeté');
	}

	getRejectedEventsFromAPI(): Event[] {
		this.emitEventSubject();
		return this.allEvents.filter((el) => el.status === 'Rejeté');
	}

	getDraftEventsFromAPI(): Event[] {
		this.emitEventSubject();
		return this.allEvents.filter((el) => el.status === 'Brouillon');
	}

	async getEventById(eventId: string) {
		return this.getEvent(eventId);
	}

	newEvent(event: Event) {
		this.saveDraftEvent(event).subscribe((data: any) => {
			this.httpClient
				.post(`${environment.apiUrl}/events/${data.id}/submit`, {}, this.httpOptions)
				.subscribe((data: any) => {
					if (data && data.name) {
						this.toasterService.showToast(
							`Votre événement ${data.name} a bien été envoyé pour validation !`,
							'Merci',
							'success',
						);
						this.router.navigateByUrl('/events');
					}
				});
		});
	}

	submitEvent(event: Event) {
		const formData = JSON.stringify(event);

		return this.httpClient.post(`${environment.apiUrl}/events/${event.id}/submit`, {}, this.httpOptions);
	}

	unpublishEvent(event: Event) {
		const formData = JSON.stringify(event);

		return this.httpClient.post(`${environment.apiUrl}/events/${event.id}/unpublish`, {}, this.httpOptions);
	}

	saveDraftEvent(event: Event): any {
		const formData = JSON.stringify(event);

		return this.httpClient.post(`${environment.apiUrl}/events`, formData, this.httpOptions);
	}

	async setEventStatus(id: string, status: string, comment?: string) {
		let response = await this.httpClient
			.post(`${environment.apiUrl}/events/${id}/${status}`, {
				comment: comment ? comment : "Non Spécifié"
			}, this.httpOptions)
			.toPromise();

		await this.getAllEventsFromAPI();

		return response;
	}

	async setEventsStatus(events: Event[], status: string) {
		const promise = new Promise((resolve, reject) => {
			events.forEach((event, index, array) => {
				this.httpClient
					.post(`${environment.apiUrl}/events/${event.id}/${status}`, {}, this.httpOptions)
					.subscribe(() => {
						if (index === array.length - 1) resolve(null);
					});
			});
		});

		return promise.then(async () => {
			await this.getAllEventsFromAPI();

			return this.allEvents;
		});
	}

	editEvent(event: Event): any {
		const formData = JSON.stringify(event);

		return this.httpClient.patch(`${environment.apiUrl}/events/${event.id}`, formData, this.httpOptions);
	}

	async getAllEventsOfUser() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/owned`).toPromise();
		this.allEvents = response;
		this.emitEventSubject();
		return this.allEvents;
	}

	async getAllEventsOfUserFromYear(year: string) {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/owned`).toPromise();
		let eventFromYear: any[] = [];
		response.forEach((event: any) => {
			if (event.eventDates?.length > 0) {
				let isInYear = event.eventDates.some((period: any) => {
					let eventYear = new Date(period.startDate).getFullYear().toString();
					return eventYear === year;
				})

				if (isInYear) eventFromYear.push(event);
			}
		});
		this.allEvents = eventFromYear;
		this.emitEventSubject();
		return this.allEvents;
	}

	async getAllEventsOfUserFrom2022() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/owned`).toPromise();
		let filteredByYearResponse = response.filter((event: any) => {
			let eventYear = new Date(event.start_date).getFullYear().toString();
			return eventYear == "2022";
		});

		this.allEvents = filteredByYearResponse;
		this.emitEventSubject();
		return this.allEvents;
	}

	async getEvent(id: string) {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/events/${id}`).toPromise();
		return response;
	}

	async getEventLocation(address?: string, zipcode?: number, city?: string) {
		const response: any = await this.httpClient.post(`${environment.apiUrl}/events/locate`, {
			address: address ? address : "",
			zipcode: zipcode ? zipcode : "",
			city: city ? city : "",
		}, this.httpOptions).toPromise();
		return response;
	}
}
