import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@interfaces/user.interface';
import { UserService } from 'app/services/user.service';
import { getUserFromToken } from 'app/utils/jwt-decode';
import { Role } from '../../enums/role.enum';

enum EventType {
	DELETE = 'delete',
	CHANGE_ROLE = 'change role',
	NEUTRAL = 'neutral',
}

@Component({
	selector: 'app-user-card',
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
	@Input() user!: User;
	isConfirmBoxOpen = false;
	kindOfEvent: EventType = EventType.NEUTRAL;
	tempRole: string | undefined = '';
	userRole = Role.MODERATOR;

	roleForm!: FormGroup;

	constructor(private formBuilder: FormBuilder, private userService: UserService) {}

	ngOnInit(): void {}

	ngOnChanges() {
		this.roleForm = this.formBuilder.group({
			role: [this.user.role],
		});

		this.roleForm.get('role')?.valueChanges.subscribe((value) => {
			this.isConfirmBoxOpen = true;
			this.kindOfEvent = EventType.CHANGE_ROLE;
			this.tempRole = value;
		});
	}

	onDelete() {
		this.kindOfEvent = EventType.DELETE;
		this.isConfirmBoxOpen = true;
	}

	onRoleChange(event: any) {
		this.isConfirmBoxOpen = true;
		this.kindOfEvent = EventType.CHANGE_ROLE;
		this.tempRole = event.target.value;
	}

	onIsConfirmed(doIReallydoThat: boolean): void {
		if (!doIReallydoThat) {
			this.isConfirmBoxOpen = false;
			return;
		}
		if (this.kindOfEvent === EventType.DELETE) this.userService.deleteUser(this.user.id);
		if (this.kindOfEvent === EventType.CHANGE_ROLE && this.tempRole)
			this.userService.changeRole(this.user.id, this.tempRole);
		this.kindOfEvent = EventType.NEUTRAL;
		this.tempRole = undefined;
		this.isConfirmBoxOpen = false;
	}
}
