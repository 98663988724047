<header class="header" [ngClass]="isNavSticky ? 'sticky-nav' : ' '" onscroll="stickyNav()">
	<div class="h-container">
		<a [href]="environment.platformUrl">
			<img class="hc-logo" src="./assets/logos/nsi-min.svg" routerLink="/" alt="logo NSI" />
		</a>
		<!-- UNCOMMENT FOR NEXT STEP -->
		<nav class="hc-nav">
			<ul class="hcn-list">
				<li
					class="hcnl-item"
					*ngIf="eventService.allPublishedEvents && eventService.allPublishedEvents.length > 0"
					routerLink="/evenements"
					routerLinkActive="active">
					Nos événements
				</li>
				<li class="hcnl-item" *ngFor="let item of navBarItems" [routerLink]="item.link" routerLinkActive="active">
					{{ item.text | uppercase }}
				</li>
				<!-- <a [href]="environment.platformUrl + item.link" class="hcnl-item" *ngFor="let item of navBarItems">{{ item.text | uppercase }}</a> -->
			</ul>
		</nav>

		<div class="hc-user-connection-container">
			<custom-button routerLink="/signin" color="primary" level="ghost" size="small">connexion</custom-button>
			<custom-button routerLink="/signup" color="primary" level="primary" size="small">inscription </custom-button>
		</div>
	</div>
</header>

<header class="header-container-mobile">
	<a [href]="environment.platformUrl">
		<img class="hcm-logo" src="./assets/logos/nsi-min.svg" routerLink="/" alt="logo NSI" />
	</a>
	<div class="hcm-burger-wrapper" (click)="toggleNavMobile()" [ngClass]="isNavMobileOpen ? 'cross' : ''">
		<span class="hcmbw-line"></span>
	</div>
</header>
<nav class="nav-container-mobile" [ngClass]="isNavMobileOpen ? 'open' : ''">
	<ul class="ncm-list">
		<li
			class="ncml-item"
			(click)="closeNavMobile()"
			*ngFor="let item of navBarItems"
			[routerLink]="item.link"
			routerLinkActive="active">
			{{ item.text | uppercase }}
		</li>
	</ul>
</nav>
