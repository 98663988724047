import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { allDepartments, Department } from '../../data/departments.data';
import { environment } from '../../../environments/environment';
import { ConfirmedValidator } from './confirmed.validators';
import { ActivatedRoute, Router } from '@angular/router';

interface ContactFormResponse {
	last_name: string;
	first_name: string;
	departement: string;
	organization_name: string;
	mail: string;
	phone: string;
	password: string;
	region?: string;
	isSubscribed: boolean;
}

export function isDepValidation(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const currentDpt = allDepartments.filter((elt: Department) => elt.num_dep === control.value);
		return currentDpt.length > 0 ? null : { isDepValidation: { value: control.value } };
	};
}

@Component({
	selector: 'app-register',
	templateUrl: './register.page.html',
	styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
	allDepartments = allDepartments;
	submitting = false;
	phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

	signUpForm!: FormGroup;
	illuContent: any;
	error = false;
	existingUserError = false;

	constructor(private httpClient: HttpClient, private formBuilder: FormBuilder, private router: Router) {}

	ngOnInit(): void {
		this.illuContent = this.illuContent = {
			image1: 'assets/images/hero-signup.jpg',
			border: '#F09E2A',
			fill: '#032830',
		};
		this.signUpForm = this.formBuilder.group(
			{
				lastname: ['', [Validators.required, Validators.minLength(1)]],
				firstname: ['', [Validators.required, Validators.minLength(1)]],
				departement: ['', [Validators.required, isDepValidation()]],
				organisme: [''],
				email: ['', [Validators.required, Validators.email]],
				phone: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
				password: ['', [Validators.required, Validators.minLength(6)]],
				passwordRepeat: ['', [Validators.required, Validators.minLength(6)]],
				isSubscribed: [false],
			},
			{
				validator: ConfirmedValidator('password', 'passwordRepeat'),
			},
		);
	}

	onSubmit = () => {
		const formValue = this.signUpForm.value;

		const region = allDepartments.filter((elt: Department) => elt.num_dep === formValue['departement']).length
			? allDepartments.filter((elt: Department) => elt.num_dep === formValue['departement'])[0].region_name
			: undefined;

		const contactFormResponse: ContactFormResponse = {
			last_name: formValue['lastname'],
			first_name: formValue['firstname'],
			departement: formValue['departement'],
			organization_name: formValue['organisme'],
			mail: formValue['email'],
			phone: formValue['phone'],
			password: formValue['password'],
			region: region && region,
			isSubscribed: formValue['isSubscribed'],
		};

		this.submitting = true;

		this.httpClient.post(`${environment.apiUrl}/users`, contactFormResponse).subscribe({
			next: () => {
				this.router.navigateByUrl('/signin?signedUp=true');
			},
			error: (error) => {
				if (error.status == 500) {
					this.submitting = false;
					this.error = true;
				} else if (error.status == 400) {
					this.submitting = false;
					this.existingUserError = true;
				}
			},
		});
	};

	get lastname() {
		return this.signUpForm.get('lastname');
	}
	get firstname() {
		return this.signUpForm.get('firstname');
	}
	get departement() {
		return this.signUpForm.get('departement');
	}
	get organisme() {
		return this.signUpForm.get('organisme');
	}
	get email() {
		return this.signUpForm.get('email');
	}
	get phone() {
		return this.signUpForm.get('phone');
	}
	get password() {
		return this.signUpForm.get('password');
	}
	get passwordRepeat() {
		return this.signUpForm.get('passwordRepeat');
	}

	get f() {
		return this.signUpForm.controls;
	}
}
