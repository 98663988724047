import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorButton } from 'app/particles/custom-button/custom-button.particle';

@Component({
	selector: 'app-page-title',
	templateUrl: './page-title.component.html',
	styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
	@Input() title = 'Événements';
	@Input() subtitle = 'Retrouvez vos évènements organisés lors de la Semaine du numérique et des sciences informatiques';
	@Input() btnText = 'Créer un nouvel événement';
	@Input() btnIcon = 'plus-outline';
	@Input() btnColor: string = ColorButton.PRIMARY;
	@Input() btnCallBackFunction?: (args?: any) => void;
	@Input() DeleteBtn?= false;
	@Input() buttonSubmit?= false;
	@Input() isButtonDisabled?: boolean;
	@Input() noButton?: boolean = false;

	constructor(private router: Router, public route: ActivatedRoute) { }

	ngOnInit(): void { }

	onClick() {
		if (this.buttonSubmit) return;
		if (this.btnCallBackFunction) {
			this.btnCallBackFunction();
		} else {
			this.router.navigate(['newevent']);
		}
	}
}
