import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Partner, Promoter, Social, WeekDatesStartEnd } from '../interfaces/collections.interface';
import { CockpitCollectionResponse } from '../interfaces/cockpit-responses.interface';

@Injectable({
	providedIn: 'root',
})
export class CollectionsService {
	private allPartners!: Partner[];
	private allPromoters!: Promoter[];
	private allSocials!: Social[];

	constructor(private httpClient: HttpClient) { }

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.log(error);
			console.log(`${operation} failed: ${error.message}`);

			return of(result as T);
		};
	}

	private getAllItemsOfCollection(collectionName: string, withPublished: boolean) {
		return this.httpClient
			.post(`${environment.cockpitURL}api/collections/get/${collectionName}`, {
				filter: withPublished
					? {
						published: true,
					}
					: false,
				populate: 1,
				lang: 'fr',
			})
			.pipe(catchError(this.handleError(`error when fetch ${collectionName}`, [])))
			.toPromise();
	}

	async getAllPartners(): Promise<Partner[]> {
		const response: any = await this.getAllItemsOfCollection('partners', true);
		return this.formatPartnersResponse(response.entries);
	}

	async getAllPromoters(): Promise<Promoter[]> {
		const response: any = await this.getAllItemsOfCollection('promoters', true);
		return this.formatPromotersResponse(response.entries);
	}

	async getAllSocials(): Promise<Social[]> {
		const response: any = await this.getAllItemsOfCollection('Socials', false);
		return this.formatSocialsResponse(response.entries);
	}

	private formatPartnersResponse(response: any[]): Partner[] {
		let arrayOfFormatedPartners: Partner[] = [];
		response.forEach((partner) => {
			arrayOfFormatedPartners = [
				...arrayOfFormatedPartners,
				{
					name: partner.name,
					image: partner.image.path,
					description: partner.description,
				},
			];
		});
		return arrayOfFormatedPartners;
	}

	private formatPromotersResponse(response: any[]) {
		let arrayOfFormatedPromoters: Promoter[] = [];
		response.forEach((promoter) => {
			arrayOfFormatedPromoters = [
				...arrayOfFormatedPromoters,
				{
					name: promoter.name,
					image: promoter.image.path,
					text: promoter.text,
				},
			];
		});
		return arrayOfFormatedPromoters;
	}

	private formatSocialsResponse(response: any[]): Social[] {
		let arrayOfFormatedSocials: Social[] = [];
		response.forEach((social) => {
			arrayOfFormatedSocials = [
				...arrayOfFormatedSocials,
				{
					name: social.name,
					link: social.link,
					icon: social.icon,
				},
			];
		});
		return arrayOfFormatedSocials;
	}

	async getNsiWeekDates(): Promise<WeekDatesStartEnd> {
		const response = (await this.getAllItemsOfCollection('eventDates', false)) as CockpitCollectionResponse;
		const entries = response.entries[0];
		return {
			startDate: entries.startDate,
			endDate: entries.endDate,
		};
	}
}
