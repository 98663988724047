import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsLetterForm } from '@interfaces/newsletter-form.interface';
import { User, UserForm } from '@interfaces/user.interface';
import { allDepartments, Department } from 'app/data/departments.data';
import { OrganizationType } from 'app/enums/organization-type.enum';
import { ImageService } from 'app/services/image.service';
import { NewsletterService } from 'app/services/newsletter.service';
import { UserService } from 'app/services/user.service';
import { getUserFromToken } from 'app/utils/jwt-decode';
import { ConfirmedValidator } from 'app/validators/confirm.validator';
import { Subscription } from 'rxjs';

class ImageSnippet {
	pending = false;
	status = 'init';

	constructor(public src: string, public file?: File) {}
}

export interface ChosenDepartment {
	num_dep: string;
	dep_name: string;
	region_name: string;
}

@Component({
	selector: 'app-settings',
	templateUrl: './settings.page.html',
	styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
	template = 'myInformations';
	allDepartments: Department[] = allDepartments;
	userLogged!: User;
	editUserForm!: FormGroup;
	selectedFile!: ImageSnippet;
	isUserHaveNewsletterProfile = false;

	chosenDepartments: string[] = [];
	chosenDepartmentsDisplayed: ChosenDepartment[] = [];

	userLoggedSubscription!: Subscription;

	orgaTypes = Object.values(OrganizationType).filter((value) => typeof value === 'string');

	organization_type = 'Entreprise';

	isAtLeastOneCheckBoxChecked = false;

	constructor(
		private formBuilder: FormBuilder,
		private imageService: ImageService,
		private newsletterService: NewsletterService,
		private userService: UserService,
	) {}

	ngOnInit(): void {
		this.userLogged = this.userService.userLogged;
		this.userLoggedSubscription = this.userService.userLoggedSubject.subscribe((userLogged: User) => {
			this.userLogged = userLogged;
		});
		this.userService.getUserNewsletterProfile();

		this.organization_type = this.userLogged.organization_type ? this.userLogged.organization_type : '';

		this.editUserForm = this.formBuilder.group(
			{
				last_name: [this.userLogged.last_name],
				first_name: [this.userLogged.first_name],
				job: [this.userLogged.job],
				mail: [this.userLogged.mail],
				phone: [this.userLogged.phone],
				password: ['', [Validators.required, Validators.minLength(6)]],
				confirmPassword: ['', Validators.required],
				organization_name: [this.userLogged.organization_name],
				organization_type: [this.userLogged.organization_type],
				alertDepartments: [[]],
				notifiedOnDepartmentsEvents: [false],
				notifiedOnOnlineEvents: [false],
				notifiedOnWeeklyResume: [false],
			},
			{
				validator: ConfirmedValidator('password', 'confirmPassword'),
			},
		);
		this.editUserForm.get('organization_type')?.patchValue(this.userLogged.organization_type);
		this.onDepartmentsChanges();
		this.initNewsletterAlert();
		this.onNotifyingChange();
	}

	getTemplateName(templateName: string) {
		this.template = templateName;
	}

	async initNewsletterAlert() {
		const newsletter = await this.userService.getUserNewsletterProfile();
		this.chosenDepartmentsDisplayed = newsletter.departments;
		if (this.chosenDepartmentsDisplayed.length > 0) this.isUserHaveNewsletterProfile = true;
		this.chosenDepartmentsDisplayed.forEach((dpt) => {
			this.chosenDepartments = [...this.chosenDepartments, dpt.num_dep];
		});
		this.editUserForm.get('notifiedOnWeeklyResume')?.setValue(newsletter.notifiedOnWeeklyResume);
		this.editUserForm.get('notifiedOnAllEvents')?.setValue(newsletter.notifiedOnAllEvents);
		this.editUserForm.get('notifiedOnDepartmentsEvents')?.setValue(newsletter.notifiedOnDepartmentsEvents);
		this.editUserForm.get('notifiedOnOnlineEvents')?.setValue(newsletter.notifiedOnOnlineEvents);
	}

	onDepartmentsChanges(): void {
		this.editUserForm.get('alertDepartments')?.valueChanges.subscribe((val) => {
			const isValAdepartment = this.allDepartments.filter((dpt) => dpt.num_dep === val).length ? true : false;
			if (this.chosenDepartments.indexOf(val) === -1 && isValAdepartment) {
				this.chosenDepartments = [...this.chosenDepartments, val];
				const departmentNameToAdd: ChosenDepartment | undefined = this.allDepartments.find(
					(elt) => elt.num_dep === val,
				);
				if (departmentNameToAdd)
					this.chosenDepartmentsDisplayed = [...this.chosenDepartmentsDisplayed, departmentNameToAdd];
				this.editUserForm.controls['alertDepartments'].reset('', { emitEvent: false });
			} else if (this.chosenDepartments.indexOf(val) != -1 && isValAdepartment) {
				this.editUserForm.controls['alertDepartments'].reset('', { emitEvent: false });
			}
		});
	}

	unChooseDepartment(depNumb: string) {
		const departmentNameToRemove: ChosenDepartment | undefined = this.allDepartments.find(
			(elt) => elt.num_dep === depNumb,
		);

		if (departmentNameToRemove) {
			this.chosenDepartmentsDisplayed = this.chosenDepartmentsDisplayed.filter(
				(elt) => elt.num_dep != departmentNameToRemove.num_dep,
			);
			this.chosenDepartments = this.chosenDepartments.filter((elt) => elt != depNumb);
		}
	}

	onNotifyingChange(): void {
		const notifiedOnDepartmentsEvents = this.editUserForm.get('notifiedOnDepartmentsEvents');
		const notifiedOnOnlineEvents = this.editUserForm.get('notifiedOnOnlineEvents');
		notifiedOnDepartmentsEvents?.valueChanges.subscribe((val) => {
			this.isAtLeastOneCheckBoxChecked = val || notifiedOnOnlineEvents?.value;
		});
		notifiedOnOnlineEvents?.valueChanges.subscribe((val) => {
			this.isAtLeastOneCheckBoxChecked = val || notifiedOnDepartmentsEvents?.value;
		});
	}

	onSave() {
		const formValues = this.editUserForm.value;

		const userDatas: UserForm = {
			last_name: formValues['last_name'],
			first_name: formValues['first_name'],
			job: formValues['job'],
			mail: formValues['mail'],
			phone: formValues['phone'],
			organization_name: formValues['organization_name'],
			organization_type: formValues['organization_type'],
		};
		if (formValues['password']) userDatas.password = formValues['password'];
		this.userService.updateUserLogged(userDatas);

		const newsletter: NewsLetterForm = {
			email: formValues['mail'],
			departments: this.chosenDepartmentsDisplayed,
			notifiedOnDepartmentsEvents: formValues['notifiedOnDepartmentsEvents'],
			notifiedOnOnlineEvents: formValues['notifiedOnOnlineEvents'],
			notifiedOnWeeklyResume: formValues['notifiedOnWeeklyResume'],
			notifiedOnAllEvents: !formValues['notifiedOnWeeklyResume'],
		};
		this.userService.updateUser(this.userLogged.id, userDatas);
		if (!this.isUserHaveNewsletterProfile) this.newsletterService.postInscription(newsletter);
		if (this.isUserHaveNewsletterProfile) this.newsletterService.patchInscription(newsletter);
	}

	get f() {
		return this.editUserForm.controls;
	}

	get password() {
		return this.editUserForm.get('password');
	}

	get confirmPassword() {
		return this.editUserForm.get('confirmPassword');
	}
}
