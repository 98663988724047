import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'app/services/toaster.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss']
})
export class UserProfilePage implements OnInit {
  isOnEdit: boolean = false;
  user!: any;
  roles: any[] = ["Administrateur", "Moderateur", "Organisateur"];

  constructor(private route: ActivatedRoute, public userService: UserService, private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.getUserDetails(this.route.snapshot.paramMap.get('id') || this.userService.sessionUser.id);
  }

  async getUserDetails(id: string) {
    if(!this.userService.allUsers || this.userService.allUsers.length < 1) {
      await this.userService.getAllUsers();
    }

    this.user = this.userService.allUsers.find((user) => user.id == id);
  }

  saveUser() {
    this.userService.editUser(this.user).subscribe((data: any) => {
      if(data && data.first_name) {
        this.user = data;
        this.toasterService.showToast(`Vos modifications ont bien été enregistrées !`, 'Merci', 'success');
        this.userService.getAllUsers();
        this.isOnEdit = false;
      }
    })
  }

}
