import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { User, UserForm } from '@interfaces/user.interface';
import { Event } from 'app/interfaces/event.interface';
import { allUsers } from '../data/fake-users.data';
import { getUserFromToken } from 'app/utils/jwt-decode';
import { NewsLetterForm } from '@interfaces/newsletter-form.interface';

interface Department {
	id: string;
	dep_name: string;
	num_dep: string;
	region_name: string;
}

export interface Newsletter {
	email: string;
	departments: Department[];
	notifiedOnDepartmentsEvents: boolean;
	notifiedOnOnlineEvents: boolean;
	notifiedOnWeeklyResume: boolean;
	notifiedOnAllEvents: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class UserService {
	allUsers: User[] = [];
	sessionUser!: User;
	usersSubject = new Subject<User[]>();
	httpOptions: any;
	emitUserSubject() {
		this.usersSubject.next(this.allUsers);
	}

	userLogged!: User;
	userLoggedSubject = new Subject<User>();
	emitUserLoggedSubject() {
		this.userLoggedSubject.next(this.userLogged);
	}

	getUserLogged() {
		const token = localStorage.getItem('token');
		if (token) this.userLogged = getUserFromToken(token);
		this.emitUserLoggedSubject();
	}

	updateUserLogged(user: UserForm) {
		if (user.last_name) this.userLogged.last_name = user.last_name;
		if (user.first_name) this.userLogged.first_name = user.first_name;
		if (user.job) this.userLogged.job = user.job;
		if (user.mail) this.userLogged.mail = user.mail;
		if (user.phone) this.userLogged.phone = user.phone;
		if (user.organization_name) this.userLogged.organization_name = user.organization_name;
		if (user.organization_type) this.userLogged.organization_type = user.organization_type;
		this.emitUserLoggedSubject();
	}

	constructor(private httpClient: HttpClient) {
		this.httpOptions = {
			headers: new HttpHeaders({
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				'Content-Type': 'application/json',
			}),
		};
	}

	getUserById(userId: string) {
		return allUsers.filter((el) => el.id == userId);
	}

	editUser(user: User): any {
		const formData = JSON.stringify(user);

		return this.httpClient.patch(`${environment.apiUrl}/users/${user.id}`, formData);
	}

	getAdminUsers(): User[] {
		return allUsers.filter((el) => el.role == 'administrateur');
	}

	getModeUsers(): User[] {
		return allUsers.filter((el) => el.role != 'organisateur');
	}

	getOrgaUsers(): User[] {
		return allUsers.filter((el) => el.role == 'organisateur');
	}

	isModerator(): boolean {
		return this.sessionUser.role != 'organisateur';
	}

	async getAllUsers() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/users`).toPromise();
		this.allUsers = response;
		this.emitUserSubject();
	}

	changeRole(id: string, role: string) {
		this.httpClient.patch(`${environment.apiUrl}/users/${id}/role`, { role }).subscribe();
	}

	deleteUser(id: string) {
		this.httpClient.delete(`${environment.apiUrl}/users/${id}`).subscribe(
			(response) => {
				this.allUsers = this.allUsers.filter((user) => user.id != id);
				this.emitUserSubject();
			},
			(error) => console.log(error),
		);
	}

	updateUser(id: string, formData: UserForm) {
		this.httpClient.patch(`${environment.apiUrl}/users/${id}`, formData).subscribe();
	}

	async getUserNewsletterProfile() {
		const response: any = await this.httpClient.get(`${environment.apiUrl}/users/profile`).toPromise();
		const newsletter: Newsletter = response.newsletter;
		return newsletter;
	}
}
