<svg id="masked-illustration" width="489" height="761" viewBox="0 0 489 761" fill="none"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path
        d="M0.81182 658.31L0.808814 658.325L0.806682 658.34C-0.283439 665.879 1.52149 671.41 6.49755 674.513C11.8123 677.864 20.2547 679.5 31.4693 679.5H179.801C185.237 679.5 189.839 679.121 193.575 678.357C197.306 677.593 200.211 676.438 202.221 674.86L202.223 674.858C206.481 671.473 209.198 665.538 210.461 657.43C210.462 657.426 210.462 657.422 210.463 657.418L333.188 61.6266L333.191 61.6127L333.193 61.5985C334.284 54.1834 332.478 48.655 327.506 45.5518C322.189 42.1359 313.744 40.5 302.531 40.5H154.199C148.718 40.5 144.094 40.8789 140.358 41.6435C136.627 42.4067 133.742 43.5619 131.775 45.1433C127.521 48.5269 124.713 54.4584 123.539 62.5728C123.538 62.5777 123.537 62.5827 123.536 62.5876L0.81182 658.31Z"
        [attr.stroke]="illuContent.border" />
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="M246.1 0H446.778C461.917 0 473.18 1.64101 480.204 4.98623C486.623 7.95275 489.045 13.2546 487.592 20.5762L409.5 318.5L408.494 318.564H342.728C337.562 318.564 332.497 318.825 327.844 319.543C323.625 320.193 317.699 321.505 312.173 324.837C301.846 331.043 296.539 340.975 294.467 350.543L215.445 639.569H80.0588C64.9202 639.569 53.6569 637.928 46.6325 634.646C40.2137 631.679 37.7916 626.377 39.2449 618.93L205.286 21.7124C206.86 13.6333 210.615 7.82652 216.186 4.54441C221.272 1.51478 231.324 0 246.1 0Z"
        [attr.fill]="illuContent.fill" />
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="M246.1 0H446.778C461.917 0 473.18 1.64101 480.204 4.98623C486.623 7.95275 489.045 13.2546 487.592 20.5762L409.5 318.5L408.494 318.564H342.728C337.562 318.564 332.497 318.825 327.844 319.543C323.625 320.193 317.699 321.505 312.173 324.837C301.846 331.043 296.539 340.975 294.467 350.543L215.445 639.569H80.0588C64.9202 639.569 53.6569 637.928 46.6325 634.646C40.2137 631.679 37.7916 626.377 39.2449 618.93L205.286 21.7124C206.86 13.6333 210.615 7.82652 216.186 4.54441C221.272 1.51478 231.324 0 246.1 0Z"
        fill="url(#pattern0)" />
    <path
        d="M293.686 747.862L293.683 747.877L293.681 747.891C293.038 752.344 294.098 755.677 297.1 757.552C300.257 759.543 305.239 760.5 311.804 760.5H398.48C401.665 760.5 404.369 760.278 406.571 759.827C408.767 759.378 410.499 758.693 411.709 757.743L411.712 757.741C414.258 755.715 415.859 752.186 416.6 747.426L488.314 399.101L488.316 399.087L488.318 399.073C488.963 394.691 487.901 391.36 484.901 389.486C481.743 387.456 476.758 386.5 470.196 386.5H383.52C380.309 386.5 377.592 386.722 375.39 387.173C373.195 387.622 371.473 388.306 370.287 389.26C367.744 391.284 366.089 394.809 365.399 399.577L293.686 747.862Z"
        [attr.stroke]="illuContent.border" />
    <path
        d="M463.221 343H342.59C333.708 343 327.665 343.926 324.608 345.778C321.259 347.785 319.002 351.335 318.056 356.274L218.245 721.382C217.372 725.935 218.828 729.177 222.686 730.99C226.909 732.997 233.679 734 242.779 734H363.411C372.22 734 378.262 733.074 381.392 731.222C384.741 729.215 386.925 725.665 387.945 720.726L487.755 355.579C488.629 351.103 487.172 347.862 483.314 346.048C479.092 344.003 472.321 343 463.221 343Z"
        [attr.fill]="illuContent.fill" />
    <path
        d="M463.221 343H342.59C333.708 343 327.665 343.926 324.608 345.778C321.259 347.785 319.002 351.335 318.056 356.274L218.245 721.382C217.372 725.935 218.828 729.177 222.686 730.99C226.909 732.997 233.679 734 242.779 734H363.411C372.22 734 378.262 733.074 381.392 731.222C384.741 729.215 386.925 725.665 387.945 720.726L487.755 355.579C488.629 351.103 487.172 347.862 483.314 346.048C479.092 344.003 472.321 343 463.221 343Z"
        fill="url(#pattern1)" />
    <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0_1382_15047" transform="translate(-0.567414) scale(0.00208479 0.00146413)" />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image1_1382_15047" transform="translate(-0.585581) scale(0.00212027 0.00146413)" />
        </pattern>
        <image id="image0_1382_15047" width="1024" height="683" [attr.xlink:href]="illuContent.image1" />
        <image id="image1_1382_15047" width="1024" height="683" [attr.xlink:href]="illuContent.image2" />
    </defs>
</svg>