import { Component, Input, OnInit } from '@angular/core';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ColorButton {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ACCENT = 'accent',
  NEUTRAL = 'neutral',
}

export enum ButtonLevel {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
}

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.particle.html',
  styleUrls: ['./custom-select.particle.scss']
})
export class CustomSelectParticle implements OnInit {
  @Input() color?: string;
  @Input() level?: string;
  @Input() size?: string;
  @Input() iconBefore?: string;
  @Input() iconAfter?: string;
  @Input() options: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
