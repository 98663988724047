import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToasterService } from './toaster.service';

@Injectable({
	providedIn: 'root',
})
export class NewsletterService {
	private isNewsletterPopupOpen = false;

	popupSubject = new Subject<boolean>();

	emitPopupSubject() {
		this.popupSubject.next(this.isNewsletterPopupOpen);
	}
	constructor(private httpClient: HttpClient, private toasterService: ToasterService) {}

	closePopup = () => {
		this.isNewsletterPopupOpen = false;
		this.emitPopupSubject();
	};

	openPopup = () => {
		this.isNewsletterPopupOpen = true;
		this.emitPopupSubject();
	};

	togglePopup = () => {
		this.isNewsletterPopupOpen = !this.isNewsletterPopupOpen;
		this.emitPopupSubject();
	};

	private handleError(error: HttpErrorResponse) {
		if (error.status === 0) {
			console.error('An error occurred:', error.error);
		} else {
			console.error(`Backend returned code ${error.status}, body was: `, error.error);
		}
		return throwError(() => new Error('Something bad happened; please try again later.'));
	}

	postInscription(data: any) {
		return this.httpClient.post(`${environment.apiUrl}/newsletters`, data).subscribe(() => {
			this.toasterService.showToast('Vos informations ont été envoyées', 'Merci', 'success');
		});
		// .pipe(catchError((error) => this.handleError(error)));
	}

	patchInscription(data: any) {
		return this.httpClient.patch(`${environment.apiUrl}/newsletters`, data).subscribe(() => {
			this.toasterService.showToast('Votre inscription a été mise à jour', 'Merci', 'success');
		});
	}
}
