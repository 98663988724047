import { Component, OnInit, Output, EventEmitter } from '@angular/core';

interface NavTabItem {
	name: string;
	templateName: string;
	isActive: boolean;
}

@Component({
	selector: 'app-tab-title',
	templateUrl: './tab-title.component.html',
	styleUrls: ['./tab-title.component.scss'],
})
export class TabTitleComponent implements OnInit {
	navLinks!: NavTabItem[];
	@Output() activeItemName: EventEmitter<string> = new EventEmitter();
	constructor() {
		this.navLinks = [
			{ name: 'Mes informations', templateName: 'myInformations', isActive: true },
			// { name: 'informations organisme', templateName: 'organism', isActive: false },
			{ name: 'Mes Alertes', templateName: 'alerts', isActive: false },
		];
	}

	ngOnInit(): void {}

	onClick(itemName: string) {
		this.navLinks.forEach((button) => {
			button.isActive = false;
		});
		const thisItem = this.navLinks.find((elt) => elt.name === itemName);

		if (thisItem) {
			this.activeItemName.emit(thisItem.templateName);
			thisItem.isActive = true;
		}
	}
}
