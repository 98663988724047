export enum EventType {
	'Ateliers, démonstrations',
	'Conférences, débats',
	'Expositions',
	'Visites d’entreprises',
	'Visites d’établissements',
	'Interventions, rencontres',
	'Journée immersion',
	'Forum des métiers, salon d’orientation',
	'Proposition d\'intervention',
	'Webinaire',
}
