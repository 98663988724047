import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Commune } from '@interfaces/commune';
import { Event } from '@interfaces/event.interface';
import { AccessType } from 'app/enums/access-type.enum';
import { EventType } from 'app/enums/event-type.enum';
import { OrganizationType } from 'app/enums/organization-type.enum';
import { PublicType } from 'app/enums/public-type.enum';
import { EventService } from 'app/services/event.service';
import { ImageService } from 'app/services/image.service';
import { ToasterService } from 'app/services/toaster.service';

// import communesData from '../../data/communes.data';
import { communes } from 'app/data/communes.data';
import { UserService } from 'app/services/user.service';
import { RegistrationCondition } from 'app/enums/registration-condition.enum';

class ImageSnippet {
	pending = false;
	status = 'init';

	constructor(public src: string, public file?: File) {}
}

@Component({
	selector: 'app-editevent',
	templateUrl: './editevent.page.html',
	styleUrls: ['./editevent.page.scss'],
})
export class EditEventPage implements OnInit {
	event: any = {
		name: '',
		type: '',
		complete: false,
		organization_name: '',
		organization_type: '',
		partners: [],
		description: '',
		periods: [],
		address: '',
		zip_code: null,
		accessibility: '',
		public: [],
		max_capacity: null,
		registration_conditions: '',
		planner_email: '',
		planner_phone: '',
		registration_link: '',
		event_img: '',
		isOnline: false,
		isRegistrationRequired: false,
		isRegistrationAllowed: true,
		isHybrid: false,
		registered_persons: 0,
		attendees: 0,
		histories: [],
		online_link: '',
		eventPeriods: [],
		eventDates: [],
	};

	isEventOnline: boolean = false;
	isRegistrationRequired: boolean = false;
	isRegistrationAllowed: boolean = true;
	eventOnlineChoice: string = '';
	isRegistrationBlocked: boolean = false;

	statusComment = '';

	history: any = [
		{
			name: 'Création',
			date: new Date(),
			comment: null,
		},
		{
			name: 'Soumission',
			date: new Date(),
			comment: null,
		},
		{
			name: 'Refus',
			date: new Date(),
			comment: 'Trop de fautes dans les textes et événements payant.',
		},
	];

	communesData: string[] = [];

	matchingCommunes: string[] = [];

	eventTypes = Object.values(EventType).filter((value) => typeof value === 'string');

	orgaTypes = Object.values(OrganizationType).filter((value) => typeof value === 'string');

	accessTypes = Object.values(AccessType).filter((value) => typeof value === 'string');

	publicTypes = Object.values(PublicType).filter((value) => typeof value === 'string');

	eventRegistrationConditions = Object.values(RegistrationCondition).filter((value) => typeof value === 'string');

	startDate: any = null;
	endDate: any = null;

	startTime: any = null;
	endTime: any = null;

	currentPartner = '';
	partners: string[] = [];

	currentPublic = '';
	public: string[] = [];

	step = '1';

	errors: any = {
		name: false,
		type: false,
		complete: false,
		organization_name: false,
		organization_type: false,
		partners: false,
		description: false,
		periods: false,
		start_date: false,
		end_date: false,
		schedules: false,
		address: false,
		zip_code: false,
		commune: false,
		accessibility: false,
		public: false,
		max_capacity: false,
		registration_conditions: false,
		planner_email: false,
		planner_phone: false,
		registration_link: false,
		event_img: false,
		event_registration: false,
		// online_link: false,
		location: false,
		eventPeriods: false,
	};

	selectedFile!: ImageSnippet;

	submitting = false;

	eventPeriods?: any[] = [];
	eventPlans: any[] = [];
	loading = true;
	eventDescriptionCurrentCount = 0;
	eventDescriptionMax = 2500;
	eventDescriptionCount = `0/${this.eventDescriptionMax}`;
	eventPeriodDescriptionCurrentCount = 0;
	eventPeriodDescriptionMax = 255;
	eventPeriodDescriptionCount = `0/${this.eventPeriodDescriptionMax}`;
	eventRejectCommentCurrentCount = 0;
	eventRejectCommentMax = 255;
	eventRejectCommentCount = `0/${this.eventRejectCommentMax}`;

	constructor(
		private imageService: ImageService,
		private eventService: EventService,
		private toasterService: ToasterService,
		private router: Router,
		private route: ActivatedRoute,
		public userService: UserService,
		private elementRef:ElementRef
	) {}

	ngOnInit() {
		this.loading = true;

		this.isRegistrationBlocked = this.event.registered_persons >= this.event.max_capacity;

		this.eventService.getAllCommunes().then((response) => {
			this.communesData = response;
		});

		this.eventService
			.getEventById(this.route.snapshot.paramMap.get('id') || '')
			.then((data) => {
				this.event = data;

				this.event.zip_code = this.event.zip_code + ' - ' + this.event.city;

				this.event.registration_conditions = this.event.registration_conditions
					? this.event.registration_conditions
					: 'Entrée libre';
				// Sort Array by most recent periods
				this.event.eventDates.sort((a: any, b: any) => {
					return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
				});

				this.event.eventDates.forEach((period: any) => {
					const tmpStartDate = new Date(period.startDate);
					const tmpEndDate = new Date(period.endDate);

					const tmpStartHour = new Date(period.startHour);
					const tmpEndHour = new Date(period.endHour);

					let startDate = this.getFullDate(tmpStartDate);
					let endDate = this.getFullDate(tmpEndDate);

					let startTime = this.getFullTime(tmpStartHour);
					let endTime = this.getFullTime(tmpEndHour);

					let timeslots: any[] = [];

					period.timeSlots.forEach((ts: any) => {
						timeslots.push({
							id: ts.id,
							start: this.getFullTime(new Date(ts.start)),
							end: this.getFullTime(new Date(ts.end)),
							label: ts.description,
						});
					});

					// Sort time ascending
					timeslots.sort(function(a,b){
						return a.start.localeCompare(b.start);
					});

					if (this.eventPeriods) {
						this.eventPeriods.push({
							id: period.id,
							description: period.description,
							startDate: startDate,
							startTime: startTime,
							endDate: endDate,
							endTime: endTime,
							eventPlans: timeslots,
						});
					}
				});

				this.isEventOnline = this.event.isHybrid || this.event.isOnline ? true : false;
				this.isRegistrationRequired = this.event.isRegistrationRequired;
				this.isRegistrationAllowed = this.event.isRegistrationAllowed;

				this.eventOnlineChoice = this.event.isHybrid ? 'hybrid' : this.event.isOnline ? 'online' : '';

				this.selectedFile = new ImageSnippet(this.event.event_img);
			})
			.finally(() => {
				this.loading = false;
			});
	}

	updatePublic() {
		if (!this.event.public.includes(this.currentPublic)) {
			this.event.public.push(this.currentPublic);
		}
	}

	onZipcodeChange(value: string): void {
		if (value.length > 1) {
			if (this.matchingCommunes.length == 0) {
				this.matchingCommunes = this.communesData.filter((elt) => elt.includes(value));
			}
		} else {
			this.matchingCommunes = [];
		}
	}

	onEventDescriptionChange() {
		this.eventDescriptionCurrentCount = this.event.description.length;
    	this.eventDescriptionCount = `${this.eventDescriptionCurrentCount}/${this.eventDescriptionMax}`;
	}

	onEventRejectCommentChange() {
		this.eventRejectCommentCurrentCount = this.statusComment.length;
    	this.eventRejectCommentCount = `${this.eventRejectCommentCurrentCount}/${this.eventRejectCommentMax}`;
	}

	onEventPeriodDescriptionChange(period: any) {
		let eventPeriodDescriptionCurrentCount = period.description.length;
    	period.descriptionCount = `${eventPeriodDescriptionCurrentCount}/${this.eventPeriodDescriptionMax}`;
	}

	duplicateEvent = async () => {
		const eventId = this.route.snapshot.paramMap.get('id') || '';
		const allEventsOfUser: Event[] = await this.eventService.getAllEventsOfUser();
		let eventToDuplicate: Event | undefined;
		if (allEventsOfUser.length) {
			eventToDuplicate = allEventsOfUser.find((event) => event.id === eventId);
			if (eventToDuplicate) {
				const { id, ...eventToSend } = eventToDuplicate;
				eventToSend.event_img = '';
				if (eventToSend.eventDates) {
					eventToSend.eventDates.forEach(ed => {
						delete ed.id;
					});
				}
				this.eventService.saveDraftEvent(eventToSend).subscribe(() => {
					this.router.navigateByUrl('/events');
				});
			}
		}
	};

	getFullTime(date: Date): string {
		return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${
			date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
		}`;
	}

	getFullDate(date: Date): string {
		return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
			date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
		}`;
	}

	updateErrors() {
		this.resetErrors();

		if (!this.event.name || this.event.name.trim().length < 1) {
			this.errors.name = true;
		}
		if (!this.event.type || this.event.type.trim().length < 1) {
			this.errors.type = true;
		}
		if (!this.event.organization_name || this.event.organization_name.trim().length < 1) {
			this.errors.organization_name = true;
		}
		if (!this.event.organization_type || this.event.organization_type.trim().length < 1) {
			this.errors.organization_type = true;
		}
		if (!this.event.description || this.event.description.trim().length < 1) {
			this.errors.description = true;
		}
		if (!this.isEventOnline) {
			if (!this.event.address || this.event.address.trim().length < 1) {
				this.errors.address = true;
			}
			if (!this.event.zip_code) {
				this.errors.zip_code = true;
			}

			if (this.event.zip_code && this.communesData.filter((elt) => elt == this.event.zip_code).length == 0) {
				this.errors.city = true;
			}

			if (!this.event.accessibility) {
				this.errors.accessibility = true;
			}
		} else {
			if (this.eventOnlineChoice == 'hybrid') {
				if (!this.event.address || this.event.address.trim().length < 1) {
					this.errors.address = true;
				}
				if (!this.event.zip_code) {
					this.errors.zip_code = true;
				}

				if (this.event.zip_code && this.communesData.filter((elt) => elt == this.event.zip_code).length == 0) {
					this.errors.city = true;
				}

				if (!this.event.accessibility) {
					this.errors.accessibility = true;
				}
			}
		}
		if (!this.event.public || this.event.public.length < 1) {
			this.errors.public = true;
		}
		if (this.event.planner_email && this.event.planner_email.trim().length > 0) {
			const emailRegex =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!emailRegex.test(this.event.planner_email.trim().toLowerCase())) {
				this.errors.planner_email = true;
			}
		}
		if (this.event.planner_phone && this.event.planner_phone.trim().length > 0) {
			const phoneRegex = /^(?:(?:\+|00)(33|590|594|262|596|269|687|689|508|681)|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
			if (!phoneRegex.test(this.event.planner_phone.trim().toLowerCase())) {
				this.errors.planner_phone = true;
			}
		}
		if (
			!this.selectedFile ||
			this.selectedFile.pending ||
			!this.selectedFile.src ||
			(this.selectedFile.file?.size && this.selectedFile.file?.size >= 2000000)
		) {
			this.errors.event_img = true;
		}
		// if (!this.event.registration_link && !this.event.registration_conditions) {
		// 	this.errors.event_registration = true;
		// }

		// if (this.isEventOnline) {
		// 	const urlRegex = '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})';
		// 	const regex = new RegExp(urlRegex);
		// 	if (!regex.test(this.event.online_link.trim().toLowerCase())) {
		// 		this.errors.online_link = true;
		// 	}
		// }

		if (
			this.eventPeriods &&
			(this.eventPeriods.length <= 0 ||
				!this.eventPeriods[0]?.startDate ||
				!this.eventPeriods[0]?.startTime ||
				!this.eventPeriods[0]?.endDate ||
				!this.eventPeriods[0]?.endTime)
		) {
			this.errors.eventPeriods = true;
		}
	}

	resetErrors() {
		this.errors = {
			name: false,
			type: false,
			complete: false,
			organization_name: false,
			organization_type: false,
			partners: false,
			description: false,
			address: false,
			zip_code: false,
			commune: false,
			accessibility: false,
			public: false,
			max_capacity: false,
			registration_conditions: false,
			planner_email: false,
			planner_phone: false,
			registration_link: false,
			event_img: false,
			event_registration: false,
			// online_link: false,
			location: false,
			eventPeriods: false,
		};
	}

	addPartner() {
		if (
			this.currentPartner &&
			this.currentPartner.trim().length > 0 &&
			this.event.partners.indexOf(this.currentPartner.trim()) === -1
		) {
			this.event.partners.push(this.currentPartner.trim());
			this.currentPartner = '';
		}
	}

	addPublic() {
		if (
			this.currentPublic &&
			this.currentPublic.trim().length > 0 &&
			this.event.public.indexOf(this.currentPublic.trim()) === -1
		) {
			this.event.public.push(this.currentPublic.trim());
			this.currentPublic = '';
		}
	}

	removePartner(index: number) {
		this.event.partners.splice(index, 1);
	}

	removePublic(index: number) {
		this.event.public.splice(index, 1);
	}

	addPlan(periodIndex: number) {
		if (this.eventPeriods) {
			this.eventPeriods[periodIndex].eventPlans.push({
				label: '',
				start: '',
				end: '',
			});
		}
	}

	removePlan(index: number, periodIndex: number) {
		if (this.eventPeriods) this.eventPeriods[periodIndex].eventPlans.splice(index, 1);
	}

	addPeriod() {
		if (this.eventPeriods) {
			this.eventPeriods.push({
				startDate: '',
				startHour: '',
				endDate: '',
				endHour: '',
				eventPlans: [],
				description: '',
				descriptionCount: '0/255',
			});
		}
	}

	removePeriod(index: number) {
		if (this.eventPeriods) this.eventPeriods.splice(index, 1);
	}

	save() {
		this.updateErrors();
		this.submitting = true;

		if (
			!this.errors.planner_email &&
			!this.errors.planner_phone &&
			!this.errors.event_img &&
			!this.errors.name &&
			!this.errors.type &&
			!this.errors.organization_name &&
			!this.errors.organization_type &&
			!this.errors.description &&
			!this.errors.address &&
			!this.errors.zip_code &&
			!this.errors.city &&
			!this.errors.commune &&
			!this.errors.accessibility &&
			!this.errors.public &&
			!this.errors.event_img &&
			!this.errors.event_registration &&
			// !this.errors.online_link &&
			!this.errors.location &&
			!this.errors.eventPeriods
		) {
			this.event.event_img = this.selectedFile.src;

			this.event.eventDates = [];

			if (this.eventPeriods) {
				this.eventPeriods.forEach((period: any) => {
					let timeslots: any[] = [];

					period.eventPlans.forEach((ts: any) => {
						const start = new Date(period.startDate);
						if (ts.start && ts.start.split(':') && ts.start.split(':')[0] && ts.start.split(':')[1]) {
							start.setHours(ts.start.split(':')[0], ts.start.split(':')[1]);
						}

						const end = new Date(period.startDate);
						if (ts.end && ts.end.split(':') && ts.end.split(':')[0] && ts.end.split(':')[1]) {
							end.setHours(ts.end.split(':')[0], ts.end.split(':')[1]);
						}

						timeslots.push({
							id: ts.id,
							start: start,
							end: end,
							description: ts.label,
						});
					});

					const startDate = new Date(period.startDate);
					if (
						period.startDate &&
						period.startDate.split(':') &&
						period.startDate.split(':')[0] &&
						period.startDate.split(':')[1]
					) {
						startDate.setHours(period.startDate.split(':')[0], period.startDate.split(':')[1]);
					}

					const startTime = new Date(period.startDate);
					if (
						period.startTime &&
						period.startTime.split(':') &&
						period.startTime.split(':')[0] &&
						period.startTime.split(':')[1]
					) {
						startTime.setHours(period.startTime.split(':')[0], period.startTime.split(':')[1]);
					}

					const endDate = new Date(period.endDate);
					if (
						period.endDate &&
						period.endDate.split(':') &&
						period.endDate.split(':')[0] &&
						period.endDate.split(':')[1]
					) {
						endDate.setHours(period.endDate.split(':')[0], period.endDate.split(':')[1]);
					}

					const endTime = new Date(period.endDate);
					if (
						period.endTime &&
						period.endTime.split(':') &&
						period.endTime.split(':')[0] &&
						period.endTime.split(':')[1]
					) {
						endTime.setHours(period.endTime.split(':')[0], period.endTime.split(':')[1]);
					}

					this.event.eventDates.push({
						id: period.id,
						description: period.description,
						startDate: startDate,
						startHour: startTime,
						endDate: endDate,
						endHour: endTime,
						timeSlots: timeslots,
					});
				});
			}

			this.event.isOnline = this.eventOnlineChoice === 'online' ? true : false;
			this.event.isHybrid = this.eventOnlineChoice === 'hybrid' ? true : false;

			if (!this.event.isHybrid && !this.event.isOnline) {
				this.event.online_link = '';
			}

			if (!this.isEventOnline || (this.isEventOnline && this.eventOnlineChoice == 'hybrid')) {
				let tempZipCode = this.event.zip_code;

				this.event.zip_code = parseInt(tempZipCode.substring(0, tempZipCode.indexOf(' - ')));
				this.event.city = tempZipCode.split(' - ').pop().trim();
			} else {
				this.event.zip_code = null;
				this.event.address = null;
				this.event.city = null;
				this.event.accessibility = '';
			}

			this.eventService.editEvent(this.event).subscribe((data: any) => {
				if (data && data.name) {
					this.toasterService.showToast(`Votre événement ${data.name} a bien été modifié !`, 'Merci', 'success');
					this.router.navigateByUrl('/events');
				}
			});
		}
	}

	trackById(index: number, item: any) {
		return item.id;
	}

	publish() {
		this.updateErrors();
		this.submitting = true;

		if (
			!this.errors.planner_email &&
			!this.errors.planner_phone &&
			!this.errors.event_img &&
			!this.errors.name &&
			!this.errors.type &&
			!this.errors.organization_name &&
			!this.errors.organization_type &&
			!this.errors.description &&
			!this.errors.address &&
			!this.errors.zip_code &&
			!this.errors.city &&
			!this.errors.commune &&
			!this.errors.accessibility &&
			!this.errors.public &&
			!this.errors.event_img &&
			!this.errors.event_registration &&
			// !this.errors.online_link &&
			!this.errors.location &&
			!this.errors.eventPeriods
		) {
			this.event.event_img = this.selectedFile.src;

			this.event.eventDates = [];

			if (this.eventPeriods) {
				this.eventPeriods.forEach((period: any) => {
					let timeslots: any[] = [];

					period.eventPlans.forEach((ts: any) => {
						const start = new Date(period.startDate);
						if (ts.start && ts.start.split(':') && ts.start.split(':')[0] && ts.start.split(':')[1]) {
							start.setHours(ts.start.split(':')[0], ts.start.split(':')[1]);
						}

						const end = new Date(period.startDate);
						if (ts.end && ts.end.split(':') && ts.end.split(':')[0] && ts.end.split(':')[1]) {
							end.setHours(ts.end.split(':')[0], ts.end.split(':')[1]);
						}

						timeslots.push({
							id: ts.id,
							start: start,
							end: end,
							description: ts.label,
						});
					});

					const startDate = new Date(period.startDate);
					if (
						period.startDate &&
						period.startDate.split(':') &&
						period.startDate.split(':')[0] &&
						period.startDate.split(':')[1]
					) {
						startDate.setHours(period.startDate.split(':')[0], period.startDate.split(':')[1]);
					}

					const startTime = new Date(period.startDate);
					if (
						period.startTime &&
						period.startTime.split(':') &&
						period.startTime.split(':')[0] &&
						period.startTime.split(':')[1]
					) {
						startTime.setHours(period.startTime.split(':')[0], period.startTime.split(':')[1]);
					}

					const endDate = new Date(period.endDate);
					if (
						period.endDate &&
						period.endDate.split(':') &&
						period.endDate.split(':')[0] &&
						period.endDate.split(':')[1]
					) {
						endDate.setHours(period.endDate.split(':')[0], period.endDate.split(':')[1]);
					}

					const endTime = new Date(period.endDate);
					if (
						period.endTime &&
						period.endTime.split(':') &&
						period.endTime.split(':')[0] &&
						period.endTime.split(':')[1]
					) {
						endTime.setHours(period.endTime.split(':')[0], period.endTime.split(':')[1]);
					}

					this.event.eventDates.push({
						id: period.id,
						description: period.description,
						startDate: startDate,
						startHour: startTime,
						endDate: endDate,
						endHour: endTime,
						timeSlots: timeslots,
					});
				});
			}

			this.event.isOnline = this.eventOnlineChoice === 'online' ? true : false;
			this.event.isHybrid = this.eventOnlineChoice === 'hybrid' ? true : false;

			if (!this.event.isHybrid && !this.event.isOnline) {
				this.event.online_link = '';
			}

			if (!this.isEventOnline || (this.isEventOnline && this.eventOnlineChoice == 'hybrid')) {
				let tempZipCode = this.event.zip_code;

				this.event.zip_code = parseInt(tempZipCode.substring(0, tempZipCode.indexOf(' - ')));
				this.event.city = tempZipCode.split(' - ').pop().trim();
			} else {
				this.event.zip_code = null;
				this.event.address = null;
				this.event.city = null;
				this.event.accessibility = '';
			}

			this.event.status = 'En Attente';

			this.eventService.editEvent(this.event).subscribe((data: any) => {
				if (data && data.name) {
					this.eventService.submitEvent(this.event).subscribe((data: any) => {
						if (data && data.name) {
							this.toasterService.showToast(
								`Votre événement ${data.name} a bien été soumis à validation !`,
								'Merci',
								'success',
							);
							this.router.navigateByUrl('/events');
						}
					});
				}
			});
		}
	}

	unpublish() {
		this.eventService.unpublishEvent(this.event).subscribe((data: any) => {
			if (data && data.name) {
				this.toasterService.showToast(`Votre événement ${data.name} a bien été dépublié`, 'Merci', 'success');
				this.router.navigateByUrl('/events');
			}
		});
	}

	private onSuccess(imageUrl: string) {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'ok';
		this.selectedFile.src = imageUrl;
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'fail';
		this.selectedFile.src = '';
	}

	async processFile(imageInput: any) {
		const file: File = imageInput.files[0];

		const reader = new FileReader();

		reader.addEventListener('load', (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);

			if (file.size >= 2000000) {
				this.errors.event_img = true;
			} else {
				this.errors.event_img = false;
				this.selectedFile.pending = true;
				this.imageService.uploadImage(this.selectedFile.file).subscribe((data: any) => {
					if (data && data.href) {
						this.onSuccess(data.href);
					} else {
						this.onError();
					}

					this.selectedFile.pending = false;
				});
			}
		});

		reader.readAsDataURL(file);
	}

	onlineChange() {
		if (this.event.isOnline) {
			this.event.isHybrid = !this.event.isOnline;
		}
	}

	hybridChange() {
		if (this.event.isHybrid) {
			this.event.isOnline = !this.event.isHybrid;
		}
	}

	eventOnlineChange() {
		if (!this.isEventOnline) {
			this.isEventOnline = false;
			this.event.isOnline = false;
			this.event.isHybrid = false;
			this.eventOnlineChoice = '';
		} else {
			this.isEventOnline = true;
			this.event.isOnline = false;
			this.event.isHybrid = true;
			this.eventOnlineChoice = 'hybrid';
		}
	}

	registrationRequiredChange() {
		if (this.event.registration_conditions === 'Sur inscription') {
			this.isRegistrationRequired = true;
		} else {
			this.isRegistrationRequired = false;
		}
		this.event.isRegistrationRequired = this.isRegistrationRequired;
	}

	registrationAllowedChange() {
		this.isRegistrationBlocked
			? (this.event.isRegistrationAllowed = false)
			: (this.event.isRegistrationAllowed = this.isRegistrationAllowed);
	}

	setStatus(str: string) {
		this.eventService.setEventStatus(this.event.id || '', str, this.statusComment || '').then((data) => {
			this.event = data;
			this.router.navigateByUrl('/events');
		});
	}
}
