<main class="user-profile">
	<section class="up-actions">
		<custom-button class="upa-btn" color="secondary" level="primary" iconBefore="arrow-back-outline" routerLink="/users"
			>Retour
		</custom-button>
	</section>
	<section class="up-content" *ngIf="user && user.first_name">
		<div class="upc-resume">
			<img src="{{user.organization_logo}}" />
			<custom-button
				*ngIf="!isOnEdit && userService.sessionUser.id === user.id"
				class="upcr-edit"
				iconAfter="edit"
				color="primary"
				level="secondary"
				(click)="isOnEdit = !isOnEdit"
				>Modifier</custom-button
			>
		</div>
		<div class="upc-infos">
			<h1 class="upci-name">{{ user.first_name }} {{ user.last_name }}</h1>
			<custom-select
				*ngIf="isOnEdit && userService.sessionUser.id !== user.id"
				class="upcr-role"
				iconAfter="chevron-down"
				color="primary"
				level="primary"
				[options]="roles"
				>{{ user.role }}
			</custom-select>
			<form action="saveUser()" class="upci-form" [ngClass]="!isOnEdit ? 'locked' : ''">
				<div class="upci-infos-group">
					<label for="job">Métier</label>
					<input type="text" name="job" value="{{ user.job }}" [disabled]="!isOnEdit" />
				</div>
				<div class="upci-infos-group">
					<label for="mail">Courriel</label>
					<input type="text" name="mail" value="{{ user.mail }}" [disabled]="!isOnEdit" />
				</div>
				<div class="upci-infos-group">
					<label for="phone">Téléphone</label>
					<input type="text" name="phone" value="{{ user.phone }}" [disabled]="!isOnEdit" />
				</div>
				<div class="upci-infos-group">
					<label for="webiste">Site Web</label>
					<input type="text" name="website" value="{{ user.website_link }}" [disabled]="!isOnEdit" />
				</div>
				<div class="upci-infos-group">
					<label for="organization">Organisation</label>
					<input type="text" name="organization" value="{{ user.organization_name }}" [disabled]="!isOnEdit" />
				</div>
				<div class="upci-infos-group">
					<label for="city">Ville</label>
					<input type="text" name="city" value="{{ user.city }}" [disabled]="!isOnEdit" />
				</div>
				<div class="upci-infos-group">
					<label for="zip">Code Postal</label>
					<input type="text" name="zip" value="{{ user.zip_code }}" [disabled]="!isOnEdit" />
				</div>
				<custom-button *ngIf="isOnEdit" type="submit" color="secondary" level="primary" (click)="saveUser()"
					>Sauvegarder
				</custom-button>
			</form>
		</div>
	</section>
</main>
