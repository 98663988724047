import { Component, OnInit } from '@angular/core';
import { isTokenOK, getUserFromToken } from '../../utils/jwt-decode';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.template.html',
	styleUrls: ['./main.template.scss'],
})
export class MainTemplate implements OnInit {
	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.userService.getUserLogged();
		const token = localStorage.getItem('token');
		if (token && isTokenOK(token)) {
			this.userService.sessionUser = getUserFromToken(token);
		}
	}
}
