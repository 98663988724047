<div class="nsi-modal" [class.openned]="active">
    <div class="modal-box">
        <div class="mm-title">
            <p>{{title}}</p>
            <i class="eva eva-close" (click)="close()"></i>
        </div>
        <div class="mm-content">
            <ng-content></ng-content>
        </div>
    </div>

</div>