import { User } from '@interfaces/user.interface';
import { Role } from 'app/enums/role.enum';

export const allUsers: User[] = [
	{
		id: 'mqodfvbepzdpcj235363sdvergeREg56',
		first_name: 'Rémi',
		last_name: 'Doe',
		role: Role.ADMIN,
		job: '',
		mail: 'doremi@youpi.com',
		phone: '0678346582',
		organization_name: 'youpi',
		organization_type: 'Club',
		organization_logo: '',
		legal_status: '',
		address: '12 rue Pasteur',
		zip_code: 44000,
		city: 'Nantes',
		department: 'Loire-Atlantique',
		region: 'Pays de la Loire',
		website_link: 'youpi.com',
		isSubscribed: true,
	},
	{
		id: 'osjzbzuvjf93JHd01dicjdW',
		first_name: 'Marcel',
		last_name: 'Dechval',
		role: Role.ADMIN,
		job: '',
		mail: 'marcel@free.fr',
		phone: '0509384585',
		organization_name: 'Centre Equestre',
		organization_type: 'Club',
		organization_logo: '',
		legal_status: '',
		address: '44 rue Pasteur',
		zip_code: 85800,
		city: 'St Gilles-Croix-de-Vie',
		department: 'Vendée',
		region: 'Pays de la Loire',
		website_link: 'https://www.chval.com',
		isSubscribed: true,
	},
	{
		id: 'hdikdifdjdid56654okokok23224',
		first_name: 'Jean',
		last_name: 'Kikool',
		role: Role.ORGANIZER,
		job: '',
		mail: 'jean@free.fr',
		phone: '0458671234',
		organization_name: 'WatèrPolo',
		organization_type: 'Sport',
		organization_logo: '',
		legal_status: '',
		address: '34 rue piscaille',
		zip_code: 50000,
		city: 'Cherbourg',
		department: 'Manche',
		region: 'Normandie',
		website_link: 'https://www.waterpolo.com',
		isSubscribed: false,
	},
	{
		id: 'aqxzrcztvzubu12bsyzczdbzub',
		first_name: 'Gislaine',
		last_name: 'Demouton',
		role: Role.MODERATOR,
		job: '',
		mail: 'gislaine@free.fr',
		phone: '0132439876',
		organization_name: "Kung-Fu l'contact",
		organization_type: 'amour et discussion',
		organization_logo: '',
		legal_status: '',
		address: '22 rue coudelatte',
		zip_code: 64000,
		city: 'Bayonne',
		department: 'Pyrénées-Atlantiques',
		region: 'Nouvelle-Aquitaine',
		website_link: 'https://www.kungfulecontact.com',
		isSubscribed: true,
	},
];
