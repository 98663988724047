<section class="container">
    <header>
        <ng-container #headerVcr></ng-container>
        <h3 *ngIf="!header?.tpl">{{ title }}</h3>
    </header>

    <main>
        <ng-container #contentVcr></ng-container>
    </main>

    <footer>
        <custom-button color="accent" level="secondary" size="small" (click)="close()">Fermer</custom-button>
        <ng-container #footerVcr></ng-container>
    </footer>
</section>