import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { ChangePasswordPage } from './pages/change-password/change-password.page';
import { EditEventPage } from './pages/editevent/editevent.page';
import { EventsPage } from './pages/events/events.page';
import { Events2022Page } from './pages/events-2022/events-2022.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { LoginPage } from './pages/login/login.page';
import { NewEventPage } from './pages/newevent/newevent.page';
import { PreviewPage } from './pages/preview/preview.page';
import { RegisterPage } from './pages/register/register.page';
import { SettingsPage } from './pages/settings/settings.page';
import { UserProfilePage } from './pages/user-profile/user-profile.page';
import { UsersPage } from './pages/users/users.page';
import { MainTemplate } from './templates/main/main.template';

const routes: Routes = [
	{
		path: 'signin',
		component: LoginPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Connexion',
			},
		},
	},
	{
		path: 'signup',
		component: RegisterPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Inscription',
			},
		},
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Mot de passe oublié',
			},
		},
	},
	{
		path: 'changement-mot-de-passe',
		component: ChangePasswordPage,
		pathMatch: 'full',
		data: {
			title: {
				text: 'Mot de passe oublié',
			},
		},
	},
	{
		path: 'evenements',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/evenements`,
		},
	},
	{
		path: 'comment-participer',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/comment-participer`,
		},
	},
	{
		path: 'contact',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/contact`,
		},
	},
	{
		path: 'evenements-2022',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/evenements-2022`,
		},
	},
	{
		path: 'faq',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/faq`,
		},
	},
	{
		path: 'a-propos',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/a-propos`,
		},
	},
	{
		path: 'contact',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/contact`,
		},
	},
	{
		path: 'espace-presse',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/espace-presse`,
		},
	},
	{
		path: 'ressources-utiles',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/ressources-utiles`,
		},
	},
	{
		path: 'mentions-legales',
		component: LoginPage,
		resolve: {
			url: 'externalUrlRedirectResolver',
		},
		data: {
			externalUrl: `${environment.platformUrl}/mentions-legales`,
		},
	},
	{
		path: '',
		component: MainTemplate,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'events',
			},
			{
				path: 'events',
				component: EventsPage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Événements',
					},
				},
			},
			{
				path: 'events-2022',
				component: Events2022Page,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Édition 2022',
					},
				},
			},
			{
				path: 'users',
				component: UsersPage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Utilisateurs',
					},
				},
			},
			{
				path: 'user/:id',
				component: UserProfilePage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Profil',
					},
				},
			},
			{
				path: 'profil',
				component: UserProfilePage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Profil',
					},
				},
			},
			{
				path: 'settings',
				component: SettingsPage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Paramètres',
					},
				},
			},
			{
				path: 'preview/:eventId',
				component: PreviewPage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Aperçu',
					},
				},
			},
			{
				path: 'newevent',
				component: NewEventPage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: 'Nouvel événement',
					},
				},
			},
			{
				path: 'editevent/:id',
				component: EditEventPage,
				canActivate: [AuthGuard],
				pathMatch: 'full',
				data: {
					title: {
						text: "Édition d'événements",
					},
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
