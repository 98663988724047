import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    httpOptions: any;
    private httpClient: HttpClient;

    constructor(private handler: HttpBackend) {
        this.httpClient = new HttpClient(this.handler);

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            })
        }
    }

    public uploadImage(image: File | undefined): any {
        const formData = new FormData();

        if (image) {
            formData.append('img', image);
        }

        return this.httpClient.post(`${environment.apiUrl}/images/upload`, formData, this.httpOptions)
    }
}