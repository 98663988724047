import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-preview',
	templateUrl: './preview.page.html',
	styleUrls: ['./preview.page.scss'],
})
export class PreviewPage implements OnInit {
	eventId!: string;
	previous!: string;

	constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) { }

	ngOnInit(): void {
		this.eventId = this.route.snapshot.paramMap.get('eventId') || '';
	}

	getSafeUrl(eventId: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.platformUrl}evenement/` + eventId);
		// return this.sanitizer.bypassSecurityTrustResourceUrl('http://localhost:62859/evenement/' + eventId);
	}

	goBack() {
		this.router.navigate(['/events']);
	}
}
