<main class="events-container">
	<app-page-title *ngIf="userService.sessionUser.role === 'Organisateur'" title="Gérer mes événements" subtitle="Retrouvez vos évènements organisés lors de la Semaine du numérique et des sciences informatiques
	">
	</app-page-title>
	<app-page-title *ngIf="userService.sessionUser.role !== 'Organisateur'" title="Gérer les événements"
		subtitle="Retrouvez vos évènements organisés lors de la Semaine du numérique et des sciences informatiques"
		[isButtonDisabled]="(userService.sessionUser.role === 'Moderateur') ? true : false">
	</app-page-title>
	<div class="content-wrapper loading" *ngIf="loading">Chargement en cours...</div>
	<div class="content-wrapper ec-unpublished" *ngIf="!loading && events && events.length > 0">
		<app-list-title *ngIf="this.userService.sessionUser.role === 'Organisateur'" [typeList]="listType"
			listName="Mes événements récents"></app-list-title>
		<app-list-title *ngIf="this.userService.sessionUser.role !== 'Organisateur'" [typeList]="listType"
			listName="Mes brouillons"></app-list-title>
		<app-event-card *ngFor="let item of events" [event]="item" role="Organisateur"></app-event-card>
	</div>
	<div class="content-wrapper ec-unpublished"
		*ngIf="!loading && userService.sessionUser.role !== 'Organisateur' && pendingEvents && pendingEvents.length > 0">
		<app-list-title [typeList]="listType" listName="Les événements en attente"> </app-list-title>
		<app-event-card *ngFor="let item of pendingEvents" [checkboxes]="true" [event]="item" [validate]="false"
			[selected]="false">
		</app-event-card>
		<div class="ecp-actions">
			<custom-button class="ecu-btn-publish" color="accent" level="ghost" size="small" iconBefore="close"
				(click)="rejectAll()">Rejeter la sélection
			</custom-button>
			<custom-button class="ecu-btn-publish" color="accent" level="primary" size="small" iconBefore="checkmark"
				(click)="publishAll()">Publier la sélection
			</custom-button>
		</div>
	</div>
	<div class="content-wrapper ec-published" *ngIf="!loading">
		<app-list-title *ngIf="userService.sessionUser.role === 'Organisateur'" [typeList]="listType"
			listName="Mes événements traités"></app-list-title>
		<app-list-title *ngIf="userService.sessionUser.role !== 'Organisateur'" [typeList]="listType"
			listName="Les événements traités"></app-list-title>
		<div class="data-table">
			<ul class="dt-tabs">
				<li class="tab-item" *ngFor="let status of allStatus" (click)="changeTab(status.key)"
					[class.active]="currentTabEvent == status.key">
					{{status.name}}
				</li>
			</ul>
			<div class="dt-datas">
				<app-event-card *ngFor="let item of getTabEvents()" [event]="item"></app-event-card>
			</div>
		</div>
	</div>
	<!-- <div class="content-wrapper ec-published" *ngIf="!loading && publishedEvents && publishedEvents.length > 0">
		<app-list-title
			*ngIf="userService.sessionUser.role === 'Organisateur'"
			[typeList]="listType"
			listName="Mes événements traités"></app-list-title>
		<app-list-title
			*ngIf="userService.sessionUser.role !== 'Organisateur'"
			[typeList]="listType"
			listName="Les événements traités"></app-list-title>
		<app-event-card *ngFor="let item of publishedEvents" [event]="item"> </app-event-card>
	</div> -->
</main>