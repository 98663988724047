<section class="change-password-section">
	<div class="cps-container">
		<div class="cpsc-images-container">
			<app-masked-illustration [illuContent]="illuContent"></app-masked-illustration>
		</div>
		<div class="cpsc-content">
			<custom-button iconBefore="arrow-ios-back-outline" color="secondary" type="button" routerLink="/signin"
				>Retour</custom-button
			>
			<div class="cpscc-title-wrapper">
				<h1 class="cpscctw-title">réintialisation mot de passe oublié</h1>
				<p class="cpscctw-subtitle">Vous pouvez désormais réintialiser votre mot de passe</p>
			</div>
			<form class="cpscc-form" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
				<div class="cpscc-item">
					<label class="cpscci-label" for="newPassword">Nouveau mot de passe :</label>
					<input
						class="cpscci-input"
						name="newPassword"
						id="newPassword"
						type="password"
						formControlName="newPassword" />
					<span *ngIf="newPassword?.invalid && newPassword?.dirty" class="error"
						>Veuillez entrer un mot de passe valide</span
					>
				</div>
				<div class="cpscc-item">
					<label class="cpscci-label" for="confirmPassword">Répéter votre mot de passe :</label>
					<input
						type="password"
						name="confirmPassword"
						id="confirmPassword"
						class="cpscci-input"
						formControlName="confirmPassword" />
					<span *ngIf="confirmPassword?.invalid && confirmPassword?.dirty" class="error"
						>Votre mot de passe ne correspond pas</span
					>
				</div>
				<custom-button [isDisabled]="changePasswordForm.invalid">Réintialiser</custom-button>
			</form>
		</div>
	</div>
</section>
