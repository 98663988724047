export enum OrganizationType {
	'Collège',
	'Lycée',
	'Enseignement supérieur',
	'Autre établissement de formation',
	'Entreprise',
	'Association',
	'Laboratoire, centre de recherche',
	'Réseau professionnel',
	'Autre',
}
