<nav class="nav-tab-title" *ngIf="navLinks">
	<ul class="ntt-list-items">
		<li
			class="nttli-item"
			*ngFor="let item of navLinks"
			[ngClass]="item.isActive ? 'active' : ''"
			(click)="onClick(item.name)">
			{{ item.name }}
		</li>
	</ul>
</nav>
