<section class="page-title">
	<div class="pt-container">
		<div class="pt-titles">
			<h1>{{ title }}</h1>
			<p>{{ subtitle }}</p>
		</div>
		<div class="pt-actions" *ngIf="!noButton">
			<custom-button
				class="pta-btn"
				[color]="btnColor"
				level="primary"
				iconBefore="{{ btnIcon }}"
				[isDisabled]="isButtonDisabled ? isButtonDisabled : false"
				(click)="onClick()"
				>{{ btnText }}</custom-button
			>
			<button class="pt-abort" *ngIf="DeleteBtn" type="button" routerLink="/events">
				<i class="eva eva-close-outline"></i>
			</button>
		</div>
	</div>
</section>
