import { Component, OnInit } from '@angular/core';
import { EventService } from 'app/services/event.service';
import { Event } from 'app/interfaces/event.interface';
import { Subscription } from 'rxjs';
import { TypeListTitle } from 'app/enums/types-list-title.enum';
import { UserService } from 'app/services/user.service';
import { Role } from 'app/enums/role.enum';

@Component({
	selector: 'app-events',
	templateUrl: './events.page.html',
	styleUrls: ['./events.page.scss'],
})
export class EventsPage implements OnInit {
	listType: TypeListTitle = TypeListTitle.EVENTS;
	currentTabEvent: string = "publish";
	events!: any[];
	publishedEvents!: Event[];
	pendingEvents!: Event[];
	modifiedEvents!: Event[];
	rejectedEvents!: Event[];
	draftEvents!: Event[];
	doneEvents!: Event[];
	eventsSubscription!: Subscription;
	selectedEvents: Event[] = [];
	loading = true;
	allStatus: any[] = [
		{
			name: 'Publié',
			key: 'publish',
			events: this.publishedEvents
		}, {
			name: 'Rejeté',
			key: 'reject',
			events: this.rejectedEvents
		}, {
			name: 'En Attente',
			key: 'pending',
			events: this.pendingEvents
		},{
			name: 'Modifié',
			key: 'modified',
			events: this.pendingEvents
		}, {
			name: 'Brouillon',
			key: 'draft',
			events: this.draftEvents
		}
	];

	indexSliceMoreEvent = 20;

	allEventsSubscription: Subscription;

	constructor(private eventService: EventService, public userService: UserService) {
		this.allEventsSubscription = this.eventService.eventSubject.subscribe((events) => {
			this.events = events.filter((el) => {
				if (el.user?.id && el.user.id.length > 0) {
					return (el.status === 'Brouillon' || el.status === 'En Attente' || el.status === 'Modifié') && el.user.id === this.userService.sessionUser.id;
				} else {
					return el.status !== 'Publié';
				}
			});
			this.pendingEvents = events.filter((el) => el.status === 'En Attente');
			this.publishedEvents = events.filter((el) => el.status === 'Publié' || el.status === 'Rejeté');
		});
	}

	async ngOnInit() {
		this.loading = true;
		if (this.userService.sessionUser.role === Role.ORGANIZER) {
			await this.eventService.getAllEventsOfUserFromYear("2023");
			this.getAllEvents();
		} else {
			await this.eventService.getAllEventsFromYear("2023");
			this.getAllEvents();
		}
		this.loading = false;
	}

	getAllEvents() {
		this.events = this.eventService.getUnpublishedEventsFromAPI();
		this.pendingEvents = this.eventService.getPendingEventsFromAPI();
		this.modifiedEvents = this.eventService.getModifiedEventsFromAPI();
		this.publishedEvents = this.eventService.getPublishedEventsFromAPI();
		this.rejectedEvents = this.eventService.getRejectedEventsFromAPI();
		this.draftEvents = this.eventService.getDraftEventsFromAPI();

		if (this.userService.sessionUser.role !== Role.ORGANIZER) {
			this.publishedEvents = this.publishedEvents.filter((el) => el.status === 'Publié');
		}
	}

	publishAll() {
		this.loading = true;
		const selectedEvents = this.events.filter((event) => {
			return event.selected;
		});

		this.eventService.setEventsStatus(selectedEvents, 'publish').then(() => {
			this.getAllEvents();
			this.loading = false;
		});
	}

	rejectAll() {
		this.loading = true;
		const selectedEvents = this.events.filter((event) => {
			return event.selected;
		});

		this.eventService.setEventsStatus(selectedEvents, 'reject').then(() => {
			this.getAllEvents();
			this.loading = false;
		});
	}

	reloadPublishedEvents(eventStatus: string) {
		this.events = this.events.filter((el) => {
			if (el.user?.id && el.user.id.length > 0) {
				return el.status === 'Brouillon' && el.user.id === this.userService.sessionUser.id;
			} else {
				return el.status !== 'Publié';
			}
		});
		this.pendingEvents = this.events.filter((el) => el.status === 'En Attente');
		this.publishedEvents = this.events.filter((el) => el.status === 'Publié' || el.status === 'Rejeté');
	}

	getTabEvents() {
		switch (this.currentTabEvent) {
			case 'publish':
				return this.publishedEvents;
			case 'reject':
				return this.rejectedEvents;
			case 'pending':
				return this.pendingEvents;
			case 'modified':
				return this.modifiedEvents;
			case 'draft':
				return this.draftEvents;
			case 'done':
				return this.doneEvents
			default:
				return this.publishedEvents;

		}

	}

	changeTab(tab: string) {
		this.currentTabEvent = tab;
	}
}
