import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isTokenOK } from 'app/utils/jwt-decode';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
	illuContent: any;

	error: any = {};
	submitting = false;

	signedUp = false;
	postError = false;

	signInForm!: FormGroup;

	constructor(
		private httpClient: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
	) {}

	ngOnInit(): void {
		this.illuContent = {
			image1: 'assets/images/hero-1.jpg',
			border: '#F09E2A',
			fill: '#BA3C12',
		};

		this.signInForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});

		this.route.queryParams.subscribe((params) => {
			this.signedUp = params.signedUp;
		});
	}

	postForm = () => {
		this.submitting = true;
		this.postError = false;

		const formValues = this.signInForm.value;

		const contactFormResponse = {
			email: formValues['email'],
			password: formValues['password'],
		};

		this.httpClient.post(`${environment.apiUrl}/auth/login`, contactFormResponse).subscribe(
			(data: any) => {
				if (data) {
					if (data.access_token) {
						localStorage.setItem('token', data.access_token);
						if (isTokenOK(data.access_token)) {
							setTimeout(() => {
								this.router.navigate(['/']);
							}, 2000);
						} else {
							this.postError = true;
						}
						this.submitting = false;
					}
				}
			},
			(err) => {
				this.postError = true;
				this.submitting = false;
			},
		);
	};

	get email() {
		return this.signInForm.get('email');
	}

	get password() {
		return this.signInForm.get('password');
	}
}
